import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import "./profile.scss";
import { useState, useContext, useEffect } from "react";
import { Context } from "../../utils/Context";
import axios from "axios";
import getToken from "../../utils/token";
import { useNavigate } from "react-router-dom";
import { CgAddR } from "react-icons/cg";
import { MdDelete, MdEdit } from "react-icons/md";
import useReset from "../../utils/hooks/useReset";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";

interface IFormInput {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  bio: string;
  level: string;
  image: string;
  social: string;
  profileImage: string;
}
const Profile = () => {
  const { state, setState } = useContext(Context)!;
  const [previewImage, setPreviewImage] = useState("");
  const [socialUrls, setSocialUrls] = useState<string[]>([]);
  const [isLoading, setisLoading] = useState(false);
  const { resetQuizFormState } = useReset();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm<IFormInput>({
    defaultValues: {
      bio: state.user?.bio,
      firstName: state.user?.firstName,
      lastName: state.user?.lastName,
      email: state.user?.email,
      mobile: String(state.user?.phone),
      image: state.user?.profileImage,
    },
  });
  const socialInput = watch("social", "");
  const [isEditingModeOn, setIsEditingModeOn] = useState(false);
  const navigate = useNavigate();
  const updateProfile = async (data: FieldValues) => {
    const formData = new FormData();
    console.log(data.image);
    formData.append("image", data.image);
    formData.append("bio", data.bio);
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("phone", data.mobile);
    formData.append("email", data.email);
    if (socialUrls.join(",").length > 0) {
      formData.append("social", socialUrls.join(","));
    }
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/admin/user/updateprofile`,
        formData,
        {
          headers: {
            Authorization: getToken(),
            ContentType: "multipart/form-data",
          },
        }
      );
      toast.success("Profile Update Successfully");
      setState((prev) => {
        return {
          ...prev,

          user: { ...response.data.userData[0] },
        };
      });
      setisLoading(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  // const inputRef = useRef<HTMLInputElement>(null);
  const onSubmit: SubmitHandler<IFormInput> = (data, e: any) => {
    setisLoading(true);
    if (!isEditingModeOn) {
      setIsEditingModeOn(true);
    } else {
      // call the update api here
      updateProfile(data);
      setIsEditingModeOn(false);
    }
  };

  useEffect(() => {
    if (state.user === null) {
    } else {
      setValue("firstName", state.user.firstName);
      setValue("lastName", state.user.lastName);
      setValue("email", state.user.email);
      setValue("mobile", String(state.user.phone));
      setValue("bio", String(state.user.bio));
      setValue("profileImage", state.user.profileImage);
      if (state.user.social === null) return;
      setSocialUrls(state.user?.social?.split(",")!);
    }
  }, [state.user]);
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setValue("image", file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    } else {
      setPreviewImage("");
    }
  };
  useEffect(() => {
    if (state.isLoggedIn === false) {
      navigate("/");
    }
    resetQuizFormState();
  }, []);
  const addUrlToList = () => {
    const v = getValues("social");
    if (v?.trim().length > 3) {
      setSocialUrls((prev) => [...prev, v?.trim()]);
      setValue("social", "");
    }
  };
  const deleteFromList = (currentIndex: number) => {
    setSocialUrls((prev) =>
      prev.filter((value, index) => currentIndex !== index)
    );
  };
  return (
    <div className="profile-container">
      <ToastContainer />
      <div className="profile-content">
        <div className="profile-form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="image-container">
              <img
                src={
                  previewImage === ""
                    ? state.user?.profileImage
                      ? `${state.user?.profileImage}?timestamp=${Date.now()}`
                      : "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fHByb2ZpbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
                    : previewImage
                }
                alt=""
              />
              <label htmlFor="image-upload">
                {isEditingModeOn && <MdEdit className="edit-icon" />}

                <input
                  id="image-upload"
                  disabled={!isEditingModeOn}
                  accept="image/*"
                  type="file"
                  {...register("image")}
                  onChange={handleImageChange}
                />
              </label>
            </div>
            <div>
              <label htmlFor="">FIRST NAME</label>
              <input
                disabled={!isEditingModeOn}
                type="text"
                id=""
                {...register("firstName", { required: true })}
              />
              {errors.firstName && <span>firstName is required.</span>}
            </div>
            <div>
              <label htmlFor="">LAST NAME</label>
              <input
                disabled={!isEditingModeOn}
                type="text"
                id=""
                {...register("lastName", { required: true })}
              />
              {errors.lastName && <span>lastName is required.</span>}
            </div>
            <div>
              <label htmlFor="">EMAIL ADDRESS</label>
              <input
                disabled={!isEditingModeOn}
                type="email"
                {...register("email", {
                  required: true,
                })}
              />
              {errors.email && <span>email is required.</span>}
            </div>

            <div>
              <label htmlFor="">MOBILE PHONE</label>
              <input
                disabled={!isEditingModeOn}
                type="text"
                {...register("mobile", {
                  required: true,
                })}
              />
              {errors.mobile && <span>mobile is required.</span>}
            </div>
            <div>
              <label htmlFor="">ADD BIO</label>
              <input
                disabled={!isEditingModeOn}
                type="text"
                {...register("bio", {
                  required: true,
                })}
              />
              {errors.bio && <span>Bio is required.</span>}
            </div>
            <div className="socials">
              {socialUrls?.length > 0 && (
                <ol>
                  {socialUrls.map((url, index) => (
                    <li key={index}>
                      {url}
                      {isEditingModeOn && (
                        <MdDelete onClick={() => deleteFromList(index)} />
                      )}
                    </li>
                  ))}
                </ol>
              )}
              <div>
                {(socialUrls === undefined || socialUrls?.length < 3) && (
                  <>
                    <label htmlFor="urls">SOCIAL'S *</label>
                    <input
                      id="urls"
                      disabled={!isEditingModeOn}
                      placeholder="Social media url"
                      {...register("social", {})}
                    />
                    {socialUrls.length < 3 && socialInput.length > 3 && (
                      <CgAddR className="add-icon" onClick={addUrlToList} />
                    )}
                  </>
                )}
              </div>
            </div>

            {isEditingModeOn && <button>SAVE</button>}
          </form>
          {!isEditingModeOn && (
            <button
              type="button"
              onClick={() => {
                setIsEditingModeOn(true);
              }}
            >
              {isLoading ? (
                <AiOutlineLoading3Quarters className="loading-icon" />
              ) : (
                "EDIT"
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
