import { DashBoardTableData } from "../../lib/types";
import { Quiz } from "../Context/ContextTypes";
export const parseQuizzesIntoTableData = (quizzes: Quiz[]) => {
  let r: DashBoardTableData[] = [];
  quizzes.forEach((i) => {
    r.push({
      date: i?.createdAt,
      id: i?.id,
      offenseteam: i.offensiveTeam?.name,
      questions: `${i?.questions.length} questions-${Math.floor(
        i?.videoDuration
      )} secs`,
      subscribedteam:
        i.subscribedTeam?.abbreviation + " - " + i.subscribedTeam?.name,
      defenseteam: i.defensiveTeam?.name,
    });
  });
  r.sort((a, b) => {
    const dateA = new Date(a.date).getTime();
    const dateB = new Date(b.date).getTime();

    return dateB - dateA;
  });
  return r;
};
