import React, { useContext, useEffect, useRef, useState } from "react";
import "./preview.scss";
import ReactPlayer from "react-player";
import { Context } from "../../../utils/Context";
import { QuestionType } from "../../../lib/types";
import QuestionCard from "./QuestionCard";
import { toast } from "react-toastify";
const filters: { [key: string]: string } = {
  "0": "none",
  "1": "Rookie",
  "2": "Pro",
  "3": "HOF",
  "4": "ALL",
};
interface questionCardType extends QuestionType {
  hasCrossed: boolean;
  percentage: number;
}
type props = {
  setIsPreviewModeOn: React.Dispatch<React.SetStateAction<boolean>>;
};
const Preview = (props: props) => {
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const { state } = useContext(Context)!;
  const [dimensions, setDimensions] = useState({ ar: 0, w: 0 });
  const { setIsPreviewModeOn } = props;
  const [previewHasStarted, setPreviewHasStarted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [timer, setTimer] = useState(0);
  const [filterMode, setFilterMode] = useState("0");
  const [questions, setQuestions] = useState<questionCardType[]>([]);

  const selectedAnAnswer = (id: string) => {
    setTimer(0);
    setQuestions((prev) => {
      return prev.map((q) => {
        if (q.id === id) return { ...q, hasCrossed: true };
        else return q;
      });
    });
  };

  useEffect(() => {
    const video = document.createElement("video");
    video.src =
      state.currentEditingQuiz !== null
        ? (state.videoUrl as string)
        : URL.createObjectURL(state.VideoFile!);

    video.addEventListener("loadedmetadata", (e) => {
      setDimensions({
        ar: parseFloat((video.videoWidth / video.videoHeight).toFixed(2)),
        w: video.videoWidth,
      });
    });
    if (filterMode === "0") {
      videoPlayerRef.current?.seekTo(0);
      setPlaying(false);
      setQuestions([]);
      return;
    }

    setTimer(0);
    const qu = state.QuizQuestions.filter(
      (q) => q.level === parseInt(filterMode) || q.level === 4
    );
    const t = qu.map((q) => {
      return {
        ...q,
        hasCrossed: false,
        percentage: parseFloat(
          ((q.time / state.videoDuration!) * 100).toFixed(2)
        ),
      };
    });
    setQuestions(t);
  }, [filterMode]);
  useEffect(() => {
    if (filterMode === "0") return;
    if (questions.length === 0) {
      toast.warn(`No Questions For \"${filters[filterMode]}\" level`, {
        autoClose: 2000,
      });
      videoPlayerRef.current?.seekTo(0);

      setPlaying(false);
      return;
    }
    if (questions.filter((q) => !q.hasCrossed).length === 0) {
      toast.warn("Questions preview is completed you can close the preview", {
        autoClose: 3000,
      });
    }

    setPlaying(true);
  }, [questions]);
  useEffect(() => {
    if (questions.length === 0) return;
    if (
      questions.filter((i) => {
        if (
          parseFloat((i.time - playedSeconds).toFixed(2)) < 0.3 &&
          parseFloat((i.time - playedSeconds).toFixed(2)) > 0 &&
          !i.hasCrossed
        )
          return i;
      }).length > 0
    ) {
      setPlaying(false);
    } else {
      return;
    }
  }, [progress]);

  return (
    <>
      <div className="preview-container">
        {!playing && !previewHasStarted && (
          <div className="levels-conatiner">
            <label htmlFor="level">Select a level to view the preview: </label>
            <select
              name="level"
              id="level"
              value={filterMode}
              onChange={(e) => {
                setFilterMode(e.target.value!);
              }}
            >
              <option value="0">none</option>
              <option value="1">Rookie</option>
              <option value="2">PRO</option>
              <option value="3">HOF</option>
              <option value="4">All</option>
            </select>
          </div>
        )}
        <div className="player-container">
          <div
            className="timer circle"
            style={{
              background: `conic-gradient(rgb(0, 255, 0) ${
                timer * 24
              }deg, black ${timer * 24}deg)`,
              visibility: !timer ? "hidden" : "visible",
            }}
          >
            <div className="circle inner">{timer}</div>
          </div>
          <ReactPlayer
            onPlay={() => {
              setPreviewHasStarted(true);
            }}
            height={"24rem"}
            width={dimensions.ar * 24 + "rem"}
            ref={videoPlayerRef}
            id="video-player"
            style={{
              padding: "0",
              margin: "0",
              border: "1px solid lightgray",
              width: "50%",
            }}
            onReady={(e) => {
              setPlaying(false);
            }}
            playing={playing}
            onEnded={() => {
              setPlaying(false);
              videoPlayerRef.current!.seekTo(0, "seconds");
              setQuestions([]);
              setFilterMode("0");
            }}
            url={state.videoUrl!}
            onProgress={(e) => {
              setPlayedSeconds(parseFloat(e.playedSeconds.toFixed(2)));
              setProgress(e.played);
            }}
            progressInterval={200}
          />
          {!playing &&
            progress < 1 &&
            questions.find((q) => q.hasCrossed === false) && (
              <QuestionCard
                setTimer={setTimer}
                submitHandler={selectedAnAnswer}
                Question={questions.find((q) => q.hasCrossed === false)!}
              />
            )}
          {/* <div className="progress-bar">
              {questions.map((q, i) => {
                return (
                  <span
                    key={i}
                    style={{
                      width: "10px",
                      height: "20px",
                      display: "inline-block",
                      zIndex: 100,
                      position: "absolute",
                      background: "green",
                      left: ((q.time / state.videoDuration!) * 100) + "%",
                      transform:"translateX(-5px)"
                    }}
                  ></span>
                );
              })}
              <div
                style={{
                  width: (progress * 100).toString() + "%",
                  height: "100%",
                  borderTopRightRadius: "5px",
                  borderBottomRightRadius: "5px",
                  backgroundColor: "white",
                  transition: "all 50ms ",
                }}
              ></div>
            </div> */}
        </div>

        <button onClick={() => setIsPreviewModeOn(false)}>close</button>
      </div>
    </>
  );
};
export default Preview;
