import React from "react";
import { RiHomeFill } from "react-icons/ri";
interface TrackerProps {
  played: number;
  isVideoPlaying: boolean;
  AmITrackingVideo: boolean;
  questionNumber?: number | null;
  MarkItForAddingNewQuestion?: () => void;
  questionStatus: "active" | "none";
  MakeThisTrackerActive?: (played: number, time: number) => void;
  isActive: boolean;
  numberOfQuestions?: number;
  time: number;
}
const Tracker = (props: TrackerProps) => {
  const {
    played,
    isVideoPlaying,
    MarkItForAddingNewQuestion,
    questionStatus,
    MakeThisTrackerActive,
    isActive,
    numberOfQuestions,
    time,
    questionNumber,
    AmITrackingVideo,
  } = props;
  return (
    <div
      onClick={() => {
        if (isVideoPlaying) return;
        if (!isVideoPlaying && questionStatus === "none") {
          MarkItForAddingNewQuestion!();
        } else {
          MakeThisTrackerActive!(played, time);
        }
      }}
      className="tracker"
      style={{ left: ` ${played * 100}%` }}
    >
      <div>
        <RiHomeFill
          className="question-index-mark"
          style={{
            color: AmITrackingVideo
              ? isVideoPlaying
                ? "#14feff"
                : "#39ff14"
              : !isActive
              ? "#39ff14"
              : "#14feff",
          }}
        />
        <span
          className="question-index"
          style={{
            backgroundColor: AmITrackingVideo
              ? isVideoPlaying
                ? "#14feff"
                : "#39ff14"
              : !isActive
              ? "#39ff14"
              : "#14feff",
          }}
        ></span>
        {!isVideoPlaying && questionStatus === "none" && (
          <span className="add-icon" title="Add question here">
            +
          </span>
        )}
        {numberOfQuestions! > 0 ? (
          <span title="Max 5 Questions allowed" className="add-icon">
            {questionNumber}
          </span>
        ) : null}
      </div>
    </div>
  );
};

export default Tracker;
