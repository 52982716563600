import React, { useState, useEffect, useContext } from "react";
import PresetQAndA from "./PresetQuestions";
import { QuestionFields, QuestionType, QuizQuestion } from "../../../lib/types";
import {
  personnel,
  positions,
  postSnap,
  preSnap,
  quarters,
  situation,
} from "../../../lib/PresetQuestionsAndAnswers";

import { Context } from "../../../utils/Context";
import useQuestionFields from "../../../utils/hooks/useQuestionFields";
import QuestionInputElem from "./QuestionInputElem";

function Question({
  toWhichLevelQuestionIsBeingAdded,
  EditQuestion,
  handleSubmit,
  questionsForLevel,
  deleteQuestion,
}: {
  toWhichLevelQuestionIsBeingAdded: string;
  handleSubmit: (levelid: number, fields: QuestionFields) => void;
  questionsForLevel?: QuestionType[];
  EditQuestion: (id: string, fields: QuestionFields) => void;
  deleteQuestion: (id: string) => void;
}) {
  const levels: { [key: string]: number } = {
    ALL: 4,
    ROOKIE: 1,
    PRO: 2,
    HOF: 3,
  };

  const {
    fields,
    questionRef,
    handleChange,
    reset,
    FillExistingQuestion,
    setFields,
  } = useQuestionFields();
  const { state } = useContext(Context)!;
  const [editId, setEditId] = useState<null | string>(null);

  const [formStatus, setFormStatus] = useState<"inactive" | "active">(
    "inactive"
  );
  const [presetMode, setPresetMode] = useState<
    "questions" | "answers" | "none"
  >("none");
  const [questionsDropDown, setQuestionsDropDown] = useState([
    {
      name: "MY QUESTIONS",
      content: Array.from(
        new Set(state.QuizQuestions.map((ite, i) => ite.question))
      ),
      isExpanded: false,
      id: 0,
    },
    {
      name: "SITUATION",
      content: situation,
      isExpanded: false,
      id: 1,
    },
    {
      name: "PRE SNAP",

      content: preSnap,
      isExpanded: false,
      id: 2,
    },
    {
      name: "POST SNAP",

      content: postSnap,
      isExpanded: false,
      id: 3,
    },
  ]);
  const [answersDropDown, setAnswersDropDown] = useState([
    {
      name: "PERSONNEL",
      content: personnel,
      isExpanded: false,
      id: 1,
    },
    {
      name: "POSITIONS",

      content: positions,
      isExpanded: false,
      id: 2,
    },
    {
      name: "QUARTERS",

      content: quarters,
      isExpanded: false,
      id: 3,
    },
  ]);
  const [dropContent, setDropContent] = useState<any[]>([]);
  const [editField, setEditField] = useState<keyof QuizQuestion | "">("");
  const togglePresetQuestions = (
    mode: "questions" | "answers",
    field: keyof QuizQuestion,
    fun?: "toggle"
  ) => {
    if (fun === "toggle") {
      setEditField(field);

      setPresetMode((prev) => {
        if (prev === mode) {
          return "none";
        } else return mode;
      });
    }
    if (mode === "questions") {
      if (presetMode === "answers") {
        setEditField(field);
        setPresetMode((prev) => {
          if (prev === mode) {
            return "none";
          } else return mode;
        });
      } else {
        setEditField("question");
        return;
      }
    } else if (mode === "answers") {
      if (presetMode === "questions") {
        setEditField(field);
        setPresetMode((prev) => {
          if (prev === mode) {
            return "none";
          } else return mode;
        });
      }
      setEditField(field);
    }
  };
  const validateInputs = () => {
    const values = { ...fields };
    let r = true;
    Object.keys(values).forEach((key) => {
      if (
        values[key as keyof typeof values].value.toString().trim().length > 0 &&
        values[key as keyof typeof values].error.trim().length === 0
      ) {
        setFields((prevFields) => ({
          ...prevFields,
          [key]: {
            ...prevFields[key as keyof typeof prevFields],

            error: "",
          },
        }));
      } else {
        setFields((prevFields) => ({
          ...prevFields,
          [key]: {
            ...prevFields[key as keyof typeof prevFields],

            error: prevFields[key as keyof typeof prevFields].error
              ? prevFields[key as keyof typeof prevFields].error
              : "This field is required",
          },
        }));
        r = false;
      }
    });
    return r;
  };

  const handleQuestionForm = (e: any) => {
    e.preventDefault();
    if (!validateInputs()) return;
    setFormStatus("inactive");
    const dataFields = {
      question: fields.question.value,
      answer: fields.answer.value,
      optionA: fields.optionA.value,
      optionB: fields.optionB.value,
      optionC: fields.optionC.value,
    };
    if (editId === null) {
      handleSubmit(
        levels[toWhichLevelQuestionIsBeingAdded.toUpperCase()],
        dataFields
      );
      setPresetMode("none");
      // reset();
    } else {
      EditQuestion(editId, dataFields);
      setPresetMode("none");
      // reset();
    }
  };

  useEffect(() => {
    setDropContent(
      presetMode === "questions" ? questionsDropDown : answersDropDown
    );
  }, [presetMode]);
  useEffect(() => {
    if (questionsForLevel?.length === 1) {
      const q = questionsForLevel[0];
      FillExistingQuestion(q);
      setEditId(q.id);
    } else {
      reset();
      setFormStatus("active");
      setEditId(null);
    }
  }, [questionsForLevel]);
  return (
    <>
      <div className="question">
        <div className="question-form">
          <div className="create-question">
            <span>
              {toWhichLevelQuestionIsBeingAdded.charAt(0).toUpperCase()}
            </span>
            <span>
              CREATE QUESTION FOR{" "}
              {toWhichLevelQuestionIsBeingAdded.toUpperCase()}
            </span>
          </div>
          <hr />
          <form id="myform" className="create-question-container">
            <div className="question-realanswer-container">
              <div>
                <label htmlFor="">QUESTION</label>
                <QuestionInputElem
                  field={fields.question}
                  formStatus={formStatus}
                  handleChange={handleChange}
                  name="question"
                  togglePresetQuestions={togglePresetQuestions}
                />
              </div>

              <div>
                <label htmlFor="">REAL ANSWER</label>
                <QuestionInputElem
                  field={fields.answer}
                  formStatus={formStatus}
                  handleChange={handleChange}
                  name="answer"
                  togglePresetQuestions={togglePresetQuestions}
                />
              </div>
            </div>
            <div className="options-container">
              <div>
                <label htmlFor="">A</label>
                <QuestionInputElem
                  field={fields.optionA}
                  formStatus={formStatus}
                  handleChange={handleChange}
                  name="optionA"
                  togglePresetQuestions={togglePresetQuestions}
                />
              </div>
              <div>
                <label htmlFor="">B</label>
                <QuestionInputElem
                  field={fields.optionB}
                  formStatus={formStatus}
                  handleChange={handleChange}
                  name="optionB"
                  togglePresetQuestions={togglePresetQuestions}
                />
              </div>
              <div>
                <label htmlFor="">C</label>
                <QuestionInputElem
                  field={fields.optionC}
                  formStatus={formStatus}
                  handleChange={handleChange}
                  name="optionC"
                  togglePresetQuestions={togglePresetQuestions}
                />
              </div>
            </div>
            <div className="buttons-container">
              {editId === null ? (
                <button
                  type="button"
                  onClick={() => {
                    reset();
                  }}
                >
                  CANCEL
                </button>
              ) : (
                <button
                  onClick={() => {
                    deleteQuestion(editId);
                  }}
                  type="button"
                >
                  Delete
                </button>
              )}
              {editId === null ? (
                <button
                  onClick={(e) => {
                    handleQuestionForm(e);
                  }}
                  type="submit"
                  form="myform"
                >
                  SUBMIT
                </button>
              ) : (
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    if (editId && formStatus === "active") {
                      handleQuestionForm(e);
                    } else {
                      questionRef.current!.focus();
                      setFormStatus("active");
                    }
                  }}
                >
                  {formStatus === "inactive" ? "Edit" : "Update"}
                </button>
              )}
            </div>
          </form>
        </div>
        {presetMode !== "none" && (
          <PresetQAndA
            setFields={setFields}
            editField={editField}
            questions={state.QuizQuestions}
            mode={presetMode}
            content={dropContent}
            setDropContent={setDropContent}
          />
        )}
      </div>
    </>
  );
}

export default Question;
