import { useContext } from "react";
import { Context } from "../Context";
const useReset = () => {
  const { setState } = useContext(Context)!;
  const resetQuizFormState = () => {
    setState((prev) => {
      return {
        ...prev,
        isSituationAdded: false,
        currentEditingQuiz: null,
        QuizQuestions: [],
        quizSituation: null,
        VideoFile: null,
        videoDuration: 0,
        videoUrl: "",
        QuizPageStatus: null,
        mode: "Football",
        isSuperAdminInspecting: false,
      };
    });
  };
  return { resetQuizFormState };
};
export default useReset;
