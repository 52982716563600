import React, { useState } from "react";
import "./presetQuestions.scss";
import { QuestionType, QuizQuestion } from "../../../lib/types";
import { BiSearch } from "react-icons/bi";
import { BsArrowRightCircle } from "react-icons/bs";

interface PropsType {
  questions: QuestionType[];
  mode: "questions" | "answers";
  content: any;
  setDropContent: any;
  setFields: React.Dispatch<React.SetStateAction<QuizQuestion>>;
  editField: keyof QuizQuestion | "";
}
const PresetQAndA = (props: PropsType) => {
  const { setFields, mode, content, setDropContent, editField } = props;

  const [searchText, setSearchText] = useState("");

  return (
    <div className="present-quetion-container">
      <div className="search-conatiner">
        <input
          type="text"
          onChange={(e) => setSearchText(e.target.value)}
          placeholder={
            mode === "questions" ? "Search Question" : "Search Answers"
          }
        />
        <BiSearch className="search" />
        <button className="filterButton">SEARCH</button>
      </div>
      <div className="dropdowns-container">
        {content.map((dropdown: any, i: any) => {
          return (
            <div key={dropdown.id} className="content-box">
              <div
                onClick={() => {
                  setDropContent((prev: any) => {
                    return prev.map((item: any, i: any) => {
                      if (item.id === dropdown.id)
                        return { ...item, isExpanded: !item.isExpanded };
                      else return { ...item, isExpanded: false };
                    });
                  });
                }}
              >
                {dropdown.name}
                <BsArrowRightCircle
                  className={`arrow ${dropdown.isExpanded && "rotate"}`}
                />
              </div>

              <ol
                className={`${
                  (dropdown.isExpanded || searchText.length > 0) && "expand"
                }`}
              >
                {dropdown.content
                  ?.filter(
                    (con: string) =>
                      con
                        .toString()
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) >= 0
                  )
                  .map((q: any) => {
                    return (
                      <li
                        onClick={() => {
                          setFields((prev) => ({
                            ...prev,
                            [editField]: { value: q.toString(), error: "" },
                          }));
                          return;
                        }}
                        key={q as string}
                      >
                        {q as string}
                      </li>
                    );
                  })}
              </ol>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PresetQAndA;
