import axios from "axios";
import { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../components/authLayout/miscellaneous/Modal";
import getToken from "../../utils/token";
import "./user.scss";
const Users = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");
  const [checked, setChecked] = useState<string[]>([]);
  const [viewEmailModal, setViewEmailModal] = useState(false);
  const getUsers = async () => {
    try {
      const response: any = await axios(
        `${process.env.REACT_APP_BACKEND_URL}/users/get-ios-users`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      setTableData(response?.data.users);
    } catch (error: any) {
      toast.warning(error.message);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getUsers().then(() => {
      setIsLoading(false);
    });
  }, []);
  /**
   * The function `handleCheck` updates a list of checked values based on the user's selection.
   */
  const handleCheck = (event: any) => {
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };

  return (
    <div className="user-container">
      <ToastContainer />
      {viewEmailModal && (
        <Modal
          checked={checked}
          setViewEmailModal={setViewEmailModal}
          setChecked={setChecked}
        />
      )}

      <div className="user">
        <div className="user-content">
          <div className="content">
            <div className="search-container">
              <div>
                <FaSearch className="search-icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search-email"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
              {checked.length > 0 && (
                <button
                  className="creator-link"
                  onClick={() => setViewEmailModal(true)}
                >
                  Send Email
                </button>
              )}
            </div>

            <div className="table-container">
              <table className="table">
                {/* this is to give specific column different width */}
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "25%" }} />
                  <col style={{ width: "35%" }} />
                </colgroup>
                <thead>
                  <tr>
                    {["User Name", "First Name", "Phone Number", "Email"].map(
                      (data, i) => (
                        <th key={i}>{data}</th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="skeleton">
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                    </tr>
                  ) : (
                    tableData
                      .filter((d: any) =>
                        d.email.toLowerCase().includes(searchText.toLowerCase())
                      )
                      .map((val: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{val.userName}</td>
                            <td>{val.firstName}</td>
                            <td>{val.phone}</td>
                            <td className="td-email">
                              <label htmlFor={val.email}>{val.email}</label>
                              <input
                                type="checkbox"
                                id={val.email}
                                value={val.email}
                                checked={checked.includes(val.email)}
                                onChange={handleCheck}
                              />
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
