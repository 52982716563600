import React, { useContext, useEffect, useState } from "react";
import "./SignIn.scss";
import { Link } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Context } from "../../utils/Context";
import { AiFillEyeInvisible, AiOutlineLoading3Quarters } from "react-icons/ai";
import { SignInUser } from "../../utils/api/auth";
import getToken from "../../utils/token";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye } from "react-icons/ai";
const SignIn = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { setState } = useContext(Context)!;
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [keepLoggedIn, setKeepLoggedIn] = useState(true);
  const LoginUser = async (inputs: FieldValues) => {
    try {
      setIsLoading(true);
      const data = await SignInUser(inputs);
      if (keepLoggedIn) {
        localStorage.setItem("token", data.accessToken);
        sessionStorage.setItem("token", data.accessToken);
      } else {
        sessionStorage.setItem("token", data.accessToken);
      }
      setState((prev) => {
        return {
          ...prev,
          isLoggedIn: true,
        };
      });
      navigate("/quiz");
      setIsLoading(false);
    } catch (err: any) {
      toast.warning(err.response.data.message[0][0]);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const token = getToken();
    if (token) {
      navigate("/quiz");
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className="main-login">
      <ToastContainer />
      <div className="login">
        <div className="onthehash">
          <span>ONTHE</span>
          <br />
          <span>HASH</span>
        </div>
        <form
          onSubmit={handleSubmit((data) => LoginUser(data))}
          className="form"
        >
          <p className="login-title">LOGIN</p>
          <label className="email" htmlFor="email">
            EMAIL ADDRESS
          </label>
          <input
            autoComplete="off"
            type="email"
            placeholder="you@example.com"
            {...register("email", { required: true })}
          />
          {errors.email && <span className="error">Email is required.</span>}
          <div className="password-label-forgot">
            <p>PASSWORD</p>
            <p className="forgot">
              <Link to={"/forgot-password"}>Forgot password?</Link>
            </p>
          </div>
          <div className="password">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", { required: true, minLength: 4 })}
              placeholder="Your password"
              autoComplete="off"
            />
            <span
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            >
              {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>
          </div>
          {errors.password && errors.password.type === "required" && (
            <span className="error">This is required</span>
          )}
          {errors.password && errors.password.type === "minLength" && (
            <span className="error">Min length should greater than 4</span>
          )}
          <p className="keep-login">
            <input
              type="checkbox"
              name=""
              id="keep-me-loggedin"
              checked={keepLoggedIn}
              onChange={() => setKeepLoggedIn(!keepLoggedIn)}
            />
            <label htmlFor="keep-me-loggedin">
              <span>Keep me logged in</span>
            </label>
          </p>
          <button>
            {isLoading ? (
              <AiOutlineLoading3Quarters className="loading-icon" />
            ) : (
              "LOG IN"
            )}
          </button>
          <p className="sign-in">
            Don't have an account? <Link to="/register">Sign up</Link>
          </p>
        </form>
        {/* {error && <p className="error">{error}</p>} */}
      </div>
      <div className="image-container">
        <img src="/signin.png" alt="" />
      </div>
    </div>
  );
};

export default SignIn;
