import axios from "axios";
import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { toast } from "react-toastify";
import getToken from "../../../utils/token";

const Modal = ({
  checked,
  setViewEmailModal,
  setChecked,
}: {
  checked: string[];
  setViewEmailModal: React.Dispatch<React.SetStateAction<boolean>>;
  setChecked: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const sendEmailApiCall = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/email-users`,
        {
          Emails: checked,
          subject,
          content: body,
        },
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
    } catch (error) {
      return error;
    }
  };

  const sendMail = () => {
    toast.promise(sendEmailApiCall, {
      error: "Unable to send mail",
      pending: "Sending",
      success: "Email send successfully to selected Users",
    });
    setViewEmailModal(false);
    setChecked([]);
  };

  return (
    <div className="overlay">
      <div className="mail-container">
        <div className="email">
          <ImCross
            color="white"
            className="cross"
            onClick={() => setViewEmailModal(false)}
          />
          <div className="to">
            <h5>To :</h5>
            <input type="text" readOnly value={String(checked)} />
          </div>
          <div className="to">
            <h5>Subject :</h5>
            <input
              type="text"
              value={subject}
              onChange={(e) => {
                setSubject(e.target.value);
              }}
            />
          </div>
          <div className="body">
            <textarea
              cols={100}
              rows={20}
              value={body}
              onChange={(e) => {
                setBody(e.target.value);
              }}
            />
          </div>
          <button onClick={sendMail}>Send Email</button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
