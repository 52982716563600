import getToken from "./token";
function JwtDecoder() {
    const token = getToken()
    if (token) {
        let base64Url = token?.split('.')[1];
        let base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/')!;
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        if (JSON.parse(jsonPayload).exp * 1000 < new Date().getTime()) {
            return false
        }
        else {
            return true
        }
    }
    else {
        return false
    }

}
export default JwtDecoder