import { FieldValues } from "react-hook-form";
import { QuizSituation } from "../Context/ContextTypes";

/**
 * The function "gettingQuizSituationFromFormData" takes in form data and returns a QuizSituation
 * object with specific properties.
 * @param {FieldValues} data - The `data` parameter is an object of type `FieldValues`. It contains the
 * form data that is being passed to the function.
 * @returns an object of type QuizSituation.
 */
export const gettingQuizSituationFromFormData = (data: FieldValues): QuizSituation => {

  const startDate = new Date();
  const endDate = new Date(startDate);
  endDate.setDate(startDate.getDate() + 30);
  return {
    startTime: new Date(),
    endTime: endDate,
    videoUrl: "www.somthing.com",
    situation: {
      distance: parseInt(data.distance),
      down: parseInt(data.down),
      offensiveTeamScore: parseInt(data.offensiveTeamScore),
      quarter: parseInt(data.quarter),
      defensiveTeamScore: parseInt(data.defensiveTeamScore),
      timeLeft: data.clock,
      yardLine: parseInt(data.yardLine),
    },
    QuizName: data.quizName,
    offensiveTeam: parseInt(data.offensiveTeam.value),
    defensiveTeam: parseInt(data.defensiveTeam.value),
    subscribedTeam: parseInt(data.subscribedTeam.value),
    playsId: "",
  };
};
