import React, { useEffect, useMemo, useState } from "react";
import "./enterQuestion.scss";
import Question from "./Question";
import { Level, QuestionFields, QuestionType } from "../../../lib/types";
import { nanoid } from "nanoid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EnterQuestion = ({
  active,

  time,
  questions,
  setQuestions,
}: {
  active: boolean;

  time: number;
  setQuestions: React.Dispatch<React.SetStateAction<QuestionType[]>>;
  questions: QuestionType[];
}) => {
  const [levelsToChoose, setLevelsToChoose] = useState<Level[]>([
    { name: "all", value: false, id: 4, NoOfQuestion: 0, isDisabled: false },
    { name: "rookie", value: false, id: 1, NoOfQuestion: 0, isDisabled: false },
    { name: "pro", value: false, id: 2, NoOfQuestion: 0, isDisabled: false },
    { name: "hof", value: false, id: 3, NoOfQuestion: 0, isDisabled: false },
  ]);

  // Adding the question to the local list of questions.
  const handleSubmit = (level: number, fields?: QuestionFields) => {
    const { optionA, optionB, optionC, answer, question } = fields!;
    setQuestions((prev) => {
      return [
        ...prev,
        {
          answer,
          level: level,
          options: [optionA, optionB, optionC],
          question,
          time,
          timingId: time,

          id: nanoid(),
        },
      ];
    });
    toast.success("Question Added to list !", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };
  // Edit the question locally
  const EditQuestion = (id: string, fields?: QuestionFields) => {
    const { optionA, optionB, optionC, answer, question } = fields!;

    setQuestions((prev) => {
      return prev.map((q, i) => {
        if (q.id === id) {
          return {
            ...q,
            answer,
            question,
            options: [optionA, optionB, optionC],
          };
        } else return q;
      });
    });

    toast.success("Question details are updated!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };
  const deleteQuestion = (id: string) => {
    setQuestions((prev) => prev.filter((q) => q.id !== id));
    toast.error("Question was deleted!", {
      position: toast.POSITION.BOTTOM_CENTER,
    });
  };

  const selectingLevel = (level: string) => {
    // we can select either "all" level or levels from the rest
    if (level === "all") {
      setLevelsToChoose((prev) => {
        return prev.map((item) => {
          if (item.name === level) return { ...item, value: !item.value };
          else return { ...item, value: false };
        });
      });
    } else {
      setLevelsToChoose((prev) => {
        return prev.map((item, i) => {
          if (item.name === "all") return { ...item, value: false };
          else if (item.name === level) return { ...item, value: !item.value };
          else return item;
        });
      });
    }
  };
  const NoOfQuestionsForGivenLevel = (
    questions: QuestionType[],
    level: number
  ) => {
    return questions.reduce((prev, current) => {
      if (current.level === level) return prev + 1;
      else return prev + 0;
    }, 0);
  };

  useEffect(() => {
    const questionsForAllLevel = questions.filter((q) => q.level === 4);
    if (questions.length > 0) {
      if (questionsForAllLevel.length > 0) {
        setLevelsToChoose((prev) => {
          return prev.map((l, i) => {
            if (l.id !== 4) return { ...l, isDisabled: true, value: false };
            else return { ...l, isDisabled: false, value: true };
          });
        });
      } else {
        let qle: number[] = [];
        questions.forEach((q) => qle.push(q.level));
        setLevelsToChoose((prev) => {
          return prev.map((l, i) => {
            if (l.id === 4) return { ...l, isDisabled: true, value: false };
            else if (qle.indexOf(l.id) >= 0)
              return { ...l, isDisabled: false, value: true };
            else return { ...l, isDisabled: false, value: false };
          });
        });
      }
    } else {
      setLevelsToChoose((prev) => {
        return prev.map((l, i) => {
          return { ...l, isDisabled: false, value: false };
        });
      });
    }
  }, [questions]);

  return (
    <>
      {active && (
        <>
          <div className="bar">
            <span>CHOOSE LEVEL:</span>
            <div className="buttons">
              <div className="rookie-buttons">
                {levelsToChoose.map((level, index) => {
                  if (level.isDisabled) return null;
                  return (
                    <div className="checkbox" key={level.id}>
                      <label
                        htmlFor={`checkbox-${index}`}
                        className={`${level.value && "active"}`}
                      >
                        {level.name.toUpperCase()}
                      </label>
                      <input
                        type="checkbox"
                        id={`checkbox-${index}`}
                        checked={level.value}
                        onChange={() => selectingLevel(level.name)}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="enter-question">
            <>
              {levelsToChoose.map((val, currentIndex) => {
                if (!val.value) return null;
                const qfl = questions.filter(
                  (item) => item.level === val?.id && item.time === time
                );

                return (
                  <Question
                    key={val.id}
                    toWhichLevelQuestionIsBeingAdded={val.name}
                    EditQuestion={EditQuestion}
                    handleSubmit={handleSubmit}
                    deleteQuestion={deleteQuestion}
                    questionsForLevel={qfl}
                  />
                );
              })}
            </>
          </div>
        </>
      )}
    </>
  );
};

export default EnterQuestion;
