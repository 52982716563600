import { ButtonHTMLAttributes } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

type Tprops = {
  label: string;
  isLoading: boolean;
  attr: ButtonHTMLAttributes<HTMLButtonElement>;
};
const ButtonWithLoadingState = (props: Tprops) => {
  const { label, isLoading, attr } = props;
  return (
    <button {...attr} disabled={isLoading}>
      {isLoading ? (
        <AiOutlineLoading3Quarters className="loading-icon" />
      ) : (
        label
      )}
    </button>
  );
};

export default ButtonWithLoadingState;
