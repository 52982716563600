import { Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./pages/dash/Dashboard";
import Protected from "./middlewares/Protected";
import SignIn from "./pages/SignInPage/SignIn";
import Register from "./pages/register/Register";
import Quiz from "./pages/quiz/Quiz";
import CreatorQuizzes from "./pages/creatorQuizzes/CreatorQuizzes";
import Profile from "./pages/profile/Profile";
import "./App.scss";
import Layout from "./components/Layout";
import AddContentCreator from "./components/authLayout/creators/AddContentCreator";
import NotFoundPage from "./pages/404Page/NotFoundPage";
import Creators from "./pages/creators/Creators";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import Links from "./pages/link/Links";
import AddLink from "./components/authLayout/link/AddLink";
import Referral from "./pages/referral/Referral";
import { useContext, useEffect } from "react";
import { Context } from "./utils/Context";
import Users from "./pages/users/Users";

function App() {
  const location = useLocation();
  const { setState } = useContext(Context)!;
  useEffect(() => {
    // Tracking the location path to maintain the scroll position from quiz to creator-quizzes and vice versa

    setState((p) => ({
      ...p,
      scroller: { ...p.scroller, pathName: location.pathname },
    }));
  }, [location]);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route element={<Layout />}>
          <Route
            path="/dash"
            element={
              <Protected>
                <Dashboard />
              </Protected>
            }
          />
          <Route
            path="/quiz"
            element={
              <Protected>
                <Quiz />
              </Protected>
            }
          />
          <Route
            path="/links"
            element={
              <Protected>
                <Links />
              </Protected>
            }
          />
          <Route path="/creators">
            <Route
              index
              element={
                <Protected>
                  <Creators />
                </Protected>
              }
            />
          </Route>
          <Route
            path="/referral"
            index
            element={
              <Protected>
                <Referral />
              </Protected>
            }
          />
          <Route
            path="/users"
            index
            element={
              <Protected>
                <Users />
              </Protected>
            }
          />
          <Route
            path="/add-content-creator"
            element={
              <Protected>
                <AddContentCreator />
              </Protected>
            }
          />
          <Route
            path="/creators-quizzes"
            element={
              <Protected>
                <CreatorQuizzes />
              </Protected>
            }
          />
          <Route
            path="/add-link"
            element={
              <Protected>
                <AddLink />
              </Protected>
            }
          />

          <Route
            path="/profile"
            element={
              <Protected>
                <Profile />
              </Protected>
            }
          />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
