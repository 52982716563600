import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { FiUserPlus } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { linkColumns } from "../../lib/StaticData";
import { DashBoardTableColumns, LinkTableData } from "../../lib/types";
import { Context } from "../../utils/Context";
import useReset from "../../utils/hooks/useReset";
import getToken from "../../utils/token";
import "./links.scss";

const Links = () => {
  const { state, setState } = useContext(Context)!;
  const [tableHeaders, setTableHeaders] =
    useState<DashBoardTableColumns[]>(linkColumns);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<LinkTableData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [deleteLinkId, setDeleteLinkId] = useState<number | null>(null);
  const { resetQuizFormState } = useReset();
  const [searchText, setSearchText] = useState("");
  const getData = async () => {
    setIsLoading(true);
    try {
      const url = `${process.env.REACT_APP_BACKEND_URL}/links`;
      const response = await axios.get(url, {
        headers: {
          Authorization: getToken(),
        },
      });
      const sortedByDate = [
        ...response.data.data.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
      ];
      setTableData(sortedByDate);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const deleteTheLink = async () => {
    // setIsLoading(true);
    const data = await axios.delete(
      `${process.env.REACT_APP_BACKEND_URL}/links`,
      {
        params: {
          id: deleteLinkId,
        },
        headers: {
          Authorization: getToken(),
        },
      }
    );
    setIsAlertOpen(false);
    toast.success("Link deleted successfully");
    const filteredData = tableData.filter(
      (data, index) => data.id !== deleteLinkId
    );
    setTableData(filteredData);

    // setIsLoading(false);
    return { data: data.data };
  };
  useEffect(() => {
    getData();
    resetQuizFormState();
    if (state.isLoggedIn === false) {
      navigate("/");
    }
  }, []);
  return (
    <div className="link-container">
      <ToastContainer />
      <div className="link">
        <div className="link-content">
          <div className="content">
            <div className="search-container">
              <div>
                <FaSearch className="search-icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
              <button
                className="add-link"
                onClick={() => navigate("/add-link")}
              >
                <FiUserPlus /> ADD NEW LINK
              </button>
            </div>

            <div className="table-container">
              <table className="table">
                {/* this is to give specific column different width */}
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "50%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                </colgroup>
                <thead>
                  <tr>
                    {tableHeaders.map((data, i) => {
                      if (data.sortingEnabled) {
                        return (
                          <th key={data.title}>
                            {data.title}
                            <span
                            //   onClick={() =>
                            //     sortTableData(data.title, data.sortingOrder)
                            //   }
                            >
                              {/* {data.sortingOrder === "asc" ? (
                                <AiOutlineSortAscending className="sorting asc" />
                              ) : (
                                <AiOutlineSortDescending className="sorting dsc" />
                              )} */}
                            </span>
                          </th>
                        );
                      } else return <th key={data.title}>{data.title}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="skeleton">
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                    </tr>
                  ) : (
                    tableData
                      .filter((d) => d.link.toLowerCase().includes(searchText))
                      .map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td className="link">
                              <a
                                href={val.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {val.link}
                              </a>
                            </td>
                            <td>
                              <a
                                target="_blank"
                                className="link"
                                href={val.mediaLinks}
                                rel="noreferrer"
                              >
                                click here
                              </a>
                            </td>

                            <td>
                              {new Date(val.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                              {/* <FiEdit
                                className="edit"
                                onClick={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    quizIdWhichIsBeingEdited: val.id,
                                  }));
                                  navigate("/quiz");
                                }}
                              /> */}
                              <MdDeleteForever
                                className="delete"
                                onClick={() => {
                                  setIsAlertOpen(true);
                                  setDeleteLinkId(val.id);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`alert delete 
  ${isAlertOpen && "show"}`}
      >
        <p>Are you sure you want to delete?</p>
        <div>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="loading-icon" />
          ) : (
            <>
              {" "}
              <button
                onClick={() => {
                  setIsAlertOpen(false);
                  setDeleteLinkId(null);
                }}
              >
                Cancel
              </button>
              <button onClick={deleteTheLink}>Delete</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Links;
