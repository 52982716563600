import axios from "axios";
import { FieldValues } from "react-hook-form";
import getToken from "../token";

export const SignInUser = async (inputs: FieldValues) => {
  const { email, password } = inputs;
  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admin/login`,
    {
      email,
      password,
    }
  );
  return data;
};
export const SendNewAccountRequest = async (userDetails: FieldValues) => {
  const { data }: any = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/admin/register`,
    {
      email: userDetails.email,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      social: userDetails.social,
      message: userDetails.message,
    }
  );
  return data;
};
export const MakingContentCreatorSuperAdmin = async (creator: {
  email: string;
  id: number;
}) => {
  const { email, id } = creator;
  try {
    if (!email || !id) throw new Error("email and id both are required");
    const apiResults = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/admin/make-super-admin`,
      { email, id },
      {
        headers: {
          Authorization: getToken(),
        },
      }
    );
    return apiResults;
  } catch (error) {
    console.log(error);
    return error;
  }
};
