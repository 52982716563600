import React from "react";
import { InputField, QuestionType, QuizQuestion } from "../../../lib/types";
import { BsMagic } from "react-icons/bs";
const placeholders: { [K in keyof QuizQuestion]: string } = {
  answer: "Add Your Answer",
  optionA: "Add Option-A",
  optionB: "Add Option-B",
  optionC: "Add Option-C",
  question: "Add Your Question",
};
type TProps = {
  name: keyof QuizQuestion;
  field: InputField;
  togglePresetQuestions: (a: any, w: any, z?: any) => void;
  handleChange: (e: any) => void;
  formStatus: "active" | "inactive";
};
const QuestionInputElem = (props: TProps) => {
  const { name, field, togglePresetQuestions, formStatus, handleChange } =
    props;
  return (
    <>
      <div className="input-box">
        <input
          title={field.value}
          type="text"
          ref={field.ref}
          name={name}
          autoComplete="off"
          onFocus={(e) => {
            togglePresetQuestions(
              name === "question" ? "questions" : "answers",
              e.target.name as keyof QuizQuestion
            );
          }}
          className={field.error && "error-input"}
          placeholder={placeholders[name]}
          required={true}
          value={field.value}
          onChange={handleChange}
          disabled={formStatus === "inactive"}
          style={{
            backgroundColor: formStatus === "inactive" ? "gray" : "",
          }}
        />
        {field.error && <span>{field.error}</span>}
      </div>
      <BsMagic
        onClick={() =>
          togglePresetQuestions(
            name === "question" ? "questions" : "answers",
            name,
            "toggle"
          )
        }
      />
    </>
  );
};

export default QuestionInputElem;
