import { useContext, useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { FiUserPlus } from "react-icons/fi";
import "./creators.scss";
import axios from "axios";
import { MdDeleteForever } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import getToken from "../../utils/token";
import { Context } from "../../utils/Context";
import useReset from "../../utils/hooks/useReset";
import Alert from "../../components/authLayout/miscellaneous/Alert";
import { MakingContentCreatorSuperAdmin } from "../../utils/api/auth";
interface Usertype {
  firstName: string;
  email: string;
  createdAt: Date;
  role: string;
  active: boolean;
  id: number;
}
type superUser = {
  email: string;
  id: null | number;
};

const Creators = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState<Usertype[]>([]);
  const { state } = useContext(Context)!;
  const [searchText, setSearchText] = useState("");
  const { resetQuizFormState } = useReset();
  const [isLoading, setIsLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [userDeleteId, setUserDeleteId] = useState<number | null>(null);
  const [userSuperDetails, setUserSuperDetails] = useState<superUser>({
    email: "",
    id: null,
  });
  const [superAlertOpen, setSuperAlertOpen] = useState(false);
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/allusers`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      const arr = [...response.data];
      arr.sort((a, b) => {
        const dateA = new Date(a.createdAt).getTime();
        const dateB = new Date(b.createdAt).getTime();

        return dateB - dateA;
      });

      setUsers(arr);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const deleteUser = async () => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_BACKEND_URL}/admin/userDelete/${userDeleteId}`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      fetchUsers();
      toast.success("user deleted successfully");
      setAlertOpen(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    if (state.isLoggedIn) {
      fetchUsers();

      resetQuizFormState();
    } else {
      navigate("/");
    }
  }, []);
  const setsuperadmin = async () => {
    toast.promise(
      MakingContentCreatorSuperAdmin({
        email: userSuperDetails.email,
        id: userSuperDetails.id as number,
      }),
      {
        error: "unable to make super admin",
        pending: "Loading",
        success: "user successfully become super admin",
      }
    );
    setUsers((prev) => {
      return prev.map((user) => {
        if (user.id === userSuperDetails.id) {
          user.role = "super_admin";
        }
        return user;
      });
    });
    // fetchUsers();
    setSuperAlertOpen(false);
  };

  return (
    <div className="users-container">
      <ToastContainer />

      <div className="users-page">
        {/* {!addUser ? ( */}
        <div className="user-content">
          <div className="content">
            <div className="search-adduser">
              <div className="search-container">
                <FaSearch />
                <input
                  type="search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  name=""
                  id=""
                  placeholder="Search"
                />
              </div>
              <button
                className="add-user"
                onClick={() => navigate("/add-content-creator")}
              >
                <FiUserPlus /> ADD NEW CREATOR
              </button>
            </div>

            <div className="table-container">
              <table className="table">
                <colgroup>
                  <col style={{ width: "15%" }} />
                  <col style={{ width: "45%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "20%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>EMAIL</th>
                    <th>CREATED DATE</th>

                    <th>ACTIONS</th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="skeleton">
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                    </tr>
                  ) : (
                    users
                      .filter((u) => u.email.toLowerCase().includes(searchText))
                      .map((user, index) => {
                        return (
                          <tr
                            key={index}
                            style={{
                              display: `${
                                user.id === state.user?.id ? "none" : ""
                              }`,
                            }}
                          >
                            <td>{user.firstName}</td>
                            <td>{user.email}</td>
                            <td>
                              {new Date(user.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                            </td>

                            <td>
                              <MdDeleteForever
                                className="delete-icon"
                                onClick={() => {
                                  setUserDeleteId(user?.id);
                                  setAlertOpen((prev) => !prev);
                                }}
                              />
                              {user.role === "super_admin" ? (
                                <img
                                  className="admin-icon"
                                  src="/icons8-superman.svg"
                                  alt="super-admin"
                                  style={{ width: "1.5rem" }}
                                  title="super-admin"
                                />
                              ) : (
                                <span
                                  onClick={() => {
                                    setUserSuperDetails({
                                      email: user.email,
                                      id: user.id,
                                    });
                                    setSuperAlertOpen((prev) => !prev);
                                  }}
                                  title="content-creator"
                                >
                                  <img
                                    className="admin-icon"
                                    src="/icons8-superman-white-color.svg"
                                    alt="super-admin"
                                    style={{ width: "1.5rem" }}
                                  />
                                </span>
                              )}
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* ) : (
          <AddUser
            setAddUser={(val) => {
              setAddUser(val);
              fetchUsers();
            }}
          />
        )} */}
      </div>
      {/* <div className={`alert delete ${alertOpen && "show"}`}>
        <p>Are you sure you want to delete?</p>
        <div>
          <button
            onClick={() => {
              setAlertOpen(false);
            }}
          >
            Cancel
          </button>
          <button onClick={deleteUser}>Delete</button>
        </div>
      </div> */}
      <Alert
        title={"Are you sure you want to delete?"}
        buttonText="Delete"
        alertOpen={alertOpen}
        setAlertOpen={setAlertOpen}
        apicall={deleteUser}
      />
      <Alert
        title={`Are you sure you want to make \"${userSuperDetails.email}"\ super admin?`}
        buttonText="Make super admin"
        alertOpen={superAlertOpen}
        setAlertOpen={setSuperAlertOpen}
        apicall={setsuperadmin}
      />
    </div>
  );
};

export default Creators;
