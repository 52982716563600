import axios from "axios";
import { QuizRequestBodyType } from "../../lib/types";
import getToken from "../token";

export const GetUserQuizzes = async (accessToken?: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/quizzes/allquizzes`,
    {
      headers: {
        Authorization: getToken() || accessToken,
      },
    }
  );
  return data;
};

export const UploadQuiz = async (quiz: QuizRequestBodyType) => {
  try {
    const { data } = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/quizzes/createQuize`,
      quiz,
      {
        headers: {
          Authorization: getToken(),
        },
      }
    );
    console.log({ data });
    return data;
  } catch (err) {
    console.log(err);
    throw err;
  }
};
export const UpdateQuiz = async (quiz: QuizRequestBodyType, quizId: number) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/quizzes/updateQuize`,
    {
      id: quizId,
      ...quiz,
    },
    {
      headers: {
        Authorization: getToken(),
      },
    }
  );
  return data;
};

export const DeleteAQuiz = async (id: number) => {
  const data = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/quizzes/quiz`,
    {
      params: {
        id: id,
      },
      headers: {
        Authorization: getToken(),
      },
    }
  );
  return { data: data.data };
};
export const UploadVideo = async (formdata: FormData) => {
  console.log(formdata);
  try {
    const headers = {
      "Content-Type": "multipart/form-data",
      Authorization: getToken(),
    };
    const url = `${process.env.REACT_APP_BACKEND_URL}/admin/upload/video`;

    const response = await axios.post(url, formdata, {
      headers,
      onUploadProgress: (e) => {
        console.log(e.progress);
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
