import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Context } from "../utils/Context";
import getToken from "../utils/token";
import axios from "axios";

function Protected({ children }: { children: any }) {
  const { pathname } = useLocation();
  const { setState } = useContext(Context)!;
  const navigate = useNavigate();
  /**
   * The function `getProfile` makes two asynchronous requests to retrieve user profile data and
   * quizzes data, and updates the state with the retrieved data.
   */
  const getProfile = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/admin/user/profile`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      const quizzes = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/quizzes/allquizzes`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      Promise.all([data, quizzes]).then(() => {
        setState((prev) => ({
          ...prev,
          user: data.data.userData[0],
          isLoggedIn: true,
          quizzes: quizzes.data,
        }));
        if (
          data.data.userData[0].role === "content_creator" &&
          (pathname === "/creators" ||
            pathname === "/referral" ||
            pathname === "/add-content-creator" ||
            pathname === "/creators-quizzes" ||
            pathname === "/add-link" ||
            pathname === "/links" ||
            pathname === "/users")
        ) {
          navigate("/quiz");
        }
      });
    } catch (error) {
      sessionStorage.removeItem("token");
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return children;
}
export default Protected;
