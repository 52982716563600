import React from "react";
import "./NotFoundPage.scss";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div className="notfound-container">
      <p>The Page you are looking is Not here</p>
      <Link to={"/dash"}>Go to Home</Link>
    </div>
  );
};

export default NotFoundPage;
