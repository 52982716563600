import { DashBoardTableColumns } from "./types";

export const dashBoardColumns: DashBoardTableColumns[] = [
  {
    title: "ID",
    sortingEnabled: true,
    sortingOrder: "none",
  },
  {
    title: "SUBSCRIBED TEAM",
    sortingEnabled: true,
    sortingOrder: "none",
  },
  {
    title: "OFFENSE TEAM",
    sortingEnabled: true,
    sortingOrder: "none",
  },
  {
    title: "DEFENSE TEAM",
    sortingEnabled: true,
    sortingOrder: "none",
  },
  {
    title: "QUESTIONS & TIME",
    sortingEnabled: false,
  },
  {
    title: "UPLOAD DATE",
    sortingEnabled: true,
    sortingOrder: "none",
  },
];
export const linkColumns: DashBoardTableColumns[] = [
  {
    title: "SL NO.",
    sortingEnabled: false,
  },
  {
    title: "LINK",
    sortingEnabled: true,
    sortingOrder: "none",
  },
  {
    title: "MEDIA LINK",
    sortingEnabled: true,
    sortingOrder: "none",
  },

  {
    title: "UPLOAD DATE",
    sortingEnabled: true,
    sortingOrder: "none",
  },
];

export const creatorColumns: DashBoardTableColumns[] = [
  {
    title: "CREATOR NAME",
    sortingEnabled: false,
  },
  {
    title: "SUBSCRIBED TEAM",
    sortingEnabled: true,
    sortingOrder: "none",
  },
  {
    title: "QUESTIONS & TIME",
    sortingEnabled: false,
  },
  {
    title: "VIEWS",
    sortingEnabled: false,
  },
  {
    title: "UPLOAD DATE",
    sortingEnabled: true,
    sortingOrder: "none",
  },
];
