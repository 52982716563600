import React, { useEffect } from 'react'
import "./QuestionCard.scss"
import { QuestionType } from '../../../lib/types'
type quetionCardProps={
    Question:QuestionType
    submitHandler:(id:string)=>void
    setTimer : React.Dispatch<React.SetStateAction<number>>
}
const QuestionCard = (prop:quetionCardProps) => {
    const {Question,submitHandler,setTimer} = prop
    let time:NodeJS.Timer
    let initTime=0
    useEffect(()=>{
        time = setInterval(()=>{

            if(initTime>=15){
                clearInterval(time)
                initTime=0
                setTimer(0)
            }else{
                setTimer(p=>p+1)
                initTime+=1
            }
        },1000)
        return ()=>{
            clearInterval(time)
        }
    },[])
  return (
    <div 
    className='question-card'
    >
        <p>{Question.question}?</p>
        <div>
            <p onClick={()=>submitHandler(Question.id)}>  <span>A</span> {Question.options[1]}</p>
            <p onClick={()=>submitHandler(Question.id)}> <span>B</span>  {Question.options[0]}</p>
        </div>    <div>
            <p onClick={()=>submitHandler(Question.id)} > <span>C</span>  {Question.options[2]}</p>
            <p onClick={()=>submitHandler(Question.id)} > <span>D</span>  {Question.answer}</p>
        </div> 
    </div>
  )
}

export default QuestionCard