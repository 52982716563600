import React, { useContext } from "react";
import "./Navbar.scss";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/Context";
import { FiLogOut } from "react-icons/fi";

const NavBar = () => {
  const { state, setState } = useContext(Context)!;
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate("/");
    setState((prev) => ({
      ...prev,
      isLoggedIn: false,
      user: null,
      quizzes: [],
      quizSituation: null,
      currentEditingQuiz: null,
      isSituationAdded: false,
      isSuperAdminInspecting: false,
      mode: "Football",
      QuizQuestions: [],
      videoDuration: null,
      Teams: null,
      tempFileNameInServer: "",
      VideoFile: null,
      videoUrl: "",
    }));
  };

  return (
    <div className="navbar-links">
      <div className="navbar">
        <div className="onthehash-navbar">
          <span>ON THE</span>
          <br />
          <span>HASH</span>
        </div>
        <div className="user">
          <Link
            to="/profile"
            className="profile-link"
            style={{ textDecoration: "none", color: "white" }}
          >
            <span>{state.user?.firstName}</span>
            <br />
            <span className="creator">{state.user?.role}</span>
          </Link>
          <img
            onClick={() => {
              navigate("/profile");
            }}
            src={
              state.user?.profileImage
                ? `${state.user?.profileImage}?timestamp=${Date.now()}`
                : "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTh8fHByb2ZpbGV8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60"
            }
            alt="profile"
          />
          <FiLogOut title="logout" className="logout" onClick={handleLogout} />
        </div>
      </div>
      <div className="links">
        {state.quizzes.length > 0 && (
          <>
            <NavLink to="/dash" className="link">
              MY QUIZZES
            </NavLink>
            |{" "}
          </>
        )}
        <NavLink to="/quiz" className="link">
          NEW QUIZ
        </NavLink>
        {state.user?.role === "super_admin" && (
          <>
            |
            <NavLink to="/creators" className="link">
              CREATORS
            </NavLink>
          </>
        )}

        {state.user?.role === "super_admin" && (
          <>
            |
            <NavLink to="/links" className="link">
              LINKS
            </NavLink>
          </>
        )}
        {state.user?.role === "super_admin" && (
          <>
            |
            <NavLink to="/creators-quizzes" className="link">
              CREATOR QUIZZES
            </NavLink>
          </>
        )}
        {state.user?.role === "super_admin" && (
          <>
            |
            <NavLink to="/referral" className="link">
              REFERRAL
            </NavLink>
          </>
        )}
        {state.user?.role === "super_admin" && (
          <>
            |
            <NavLink to="/users" className="link">
              USERS
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default NavBar;
