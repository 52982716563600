import axios from "axios";
import React, { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const ResetPasswordForm = ({
  setFormStages,
  email,
}: {
  setFormStages: React.Dispatch<
    React.SetStateAction<{
      sendotp: boolean;
      setNewPassword: boolean;
    }>
  >;
  email: string;
}) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const verifyOtp = async (data: FieldValues) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/reset-password`,
        {
          email,
          code: data.code,
          password: data.password,
        }
      );
      setIsLoading(false);

      toast.success("password changed successfully");
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error: any) {
      setIsLoading(false);

      toast.warning(error.response.data.error);
    }
  };
  return (
    <>
      <ToastContainer />
      <form className="form" onSubmit={handleSubmit((data) => verifyOtp(data))}>
        <p className="login-title">RESET PASSWORD</p>
        <label htmlFor="email">ENTER OTP *</label>
        <input
          type="text"
          placeholder="Enter OTP here"
          {...register("code", { required: true })}
        />
        {errors.code && <span>OTP is required.</span>}
        <label htmlFor="password">NEW PASSWORD *</label>
        <input
          type="password"
          placeholder="Enter New Password"
          {...register("password", {
            required: true,
            minLength: 8,
          })}
        />
        {errors.password && errors.password.type === "required" && (
          <span>This is required</span>
        )}
        {errors.password && errors.password.type === "minLength" && (
          <span>Min length should greater than 8</span>
        )}
        <label htmlFor="password"> RE ENTER NEW PASSWORD *</label>
        <input
          type="password"
          placeholder="Re Enter New Password"
          {...register("repassword", {
            validate: (val: string) => {
              if (watch("password") !== val) {
                return "Your passwords do no match";
              }
            },
            required: "re enter the password",
          })}
        />
        {errors.repassword && (
          <span>{errors.repassword.message?.toString()}</span>
        )}
        <button>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="loading-icon" />
          ) : (
            "Reset Password"
          )}
        </button>
      </form>
    </>
  );
};

export default ResetPasswordForm;
