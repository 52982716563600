import React, { useEffect, useState } from "react";

const useDebounce = (value: number, delay: number) => {
  const [debounceValue, setDebounceValue] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebounceValue(value);
    }, delay);
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  return { debounceValue };
};

export default useDebounce;
