import React, { useEffect, useContext, useState } from "react";
import { Context } from "../../../utils/Context";
import { useNavigate } from "react-router-dom";
import "./addlink.scss";
import { FieldValues, useForm } from "react-hook-form";
import {
  AiFillEye,
  AiFillEyeInvisible,
  AiOutlineFullscreen,
  AiOutlineLoading3Quarters,
} from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { UploadVideo } from "../../../utils/api/quiz";
import axios from "axios";
import getToken from "../../../utils/token";
import { ToastContainer, toast } from "react-toastify";

const AddLink = () => {
  const { state, setState } = useContext(Context)!;
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [dataurl, setDataURL] = useState("");
  const [type, setType] = useState("");
  const file = watch("file");

  const upload = async (data: FieldValues) => {
    try {
      setIsLoading(true);
      const formdata = new FormData();
      formdata.append("file", data.file[0]!);
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: getToken(),
      };
      const url = `${process.env.REACT_APP_BACKEND_URL}/admin/upload/video`;

      const response = await axios.post(url, formdata, {
        headers,
        // onUploadProgress: (e) => {
        //   console.log(e.progress);
        // },
      });
      const uploadLink = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/links`,

        {
          link: data.link,
          mediaLinks: response.data.link,
          type: data.file[0].type.split("/")[0],
        },
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const addUserLink = (data: FieldValues) => {
    toast
      .promise(upload(data), {
        error: "sorry unable to upload video",
        pending: "media is uploading wait for a while",
        success: "Link uploaded successfully",
      })
      .then(() => {
        setTimeout(() => {
          navigate("/links");
        }, 2000);
      });
  };

  useEffect(() => {
    if (file && file.length > 0) {
      setType(file[0].type);
      setDataURL(URL.createObjectURL(file[0]));
    }
  }, [file]);

  useEffect(() => {
    if (state.isLoggedIn === false) {
      navigate("/");
    }
  }, []);
  return (
    <div className="link-container">
      <ToastContainer />

      <div className="new-link">
        <div className="header-cross">
          <h3>ADD NEW LINK</h3>
          <ImCross className="close" onClick={() => navigate("/links")} />
        </div>
        {showPreview && (
          <div id="overlay">
            <div id="video">
              <ImCross
                className="close"
                color="white"
                onClick={() => setShowPreview(false)}
              />

              {type.includes("video") ? (
                <div className="video-container">
                  <video controls>
                    <source src={dataurl} />
                  </video>
                </div>
              ) : (
                <img
                  src={dataurl}
                  height={500}
                  width={500}
                  style={{ objectFit: "contain" }}
                  alt=""
                />
              )}
            </div>
          </div>
        )}
        <form
          className="link-form"
          onSubmit={handleSubmit((data) => addUserLink(data))}
        >
          <label htmlFor="LINK">LINK*</label>
          <input
            type="text"
            id="link"
            {...register("link", { required: true })}
          />
          {errors.link && <span className="error">Link is required.</span>}

          <div>
            <label htmlFor="file">File*</label>
            <input
              type="file"
              id="file"
              accept="video/*,image/*"
              {...register("file", { required: true })}
            />

            <span
              onClick={() => {
                setShowPreview((prev) => !prev);
              }}
            >
              {!showPreview && <AiOutlineFullscreen />}
            </span>
          </div>
          {errors.file && <span className="error">File is required.</span>}
          <button>
            {isLoading ? (
              <AiOutlineLoading3Quarters className="loading-icon" />
            ) : (
              "ADD LINK"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddLink;
