import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { IQuizFormValues, QuizFormTeamField } from "../../../lib/types";
import { Context } from "../../../utils/Context";
import "./quizForm.scss";
import Select, { StylesConfig } from "react-select";
import { toast } from "react-toastify";
import {
  DeleteAQuiz,
  GetUserQuizzes,
  UpdateQuiz,
  UploadQuiz,
} from "../../../utils/api/quiz";
import { CreateQuizBodyFromState } from "../../../utils/helperFunctions/CreateQuizBodyFromState";
import { gettingQuizSituationFromFormData } from "../../../utils/helperFunctions/GetQuizSituation";
import useReset from "../../../utils/hooks/useReset";
import getToken from "../../../utils/token";
import ButtonWithLoadingState from "../../ButtonWithLoadingState";
import Preview from "./preview";

const QuizForm = ({
  setIsFormSubmitted,
}: {
  setIsFormSubmitted: (val: boolean) => void;
}) => {
  const { setState, state } = useContext(Context)!;
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<null | number>(null);
  const [defaultValues, setDefaultValues] = useState<IQuizFormValues>({
    quizName: "Foot ball",
    defensiveTeamScore: 0,
    offensiveTeamScore: 0,
    quarter: 1,
    clock: "00:00",
    down: 0,
    distance: 0,
    yardLine: 0,
    defensiveTeam: null,
    offensiveTeam: null,
    subscribedTeam: null,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm<IQuizFormValues>({ values: defaultValues });
  const [teams, setTeams] = useState<QuizFormTeamField[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isQuizBeingUploaded, setIsQuizBeingUploaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const { resetQuizFormState } = useReset();
  const [isPreviewModeOn, setIsPreviewModeOn] = useState(false);
  const OnChange = (e: any) => {
    setDefaultValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const toggleShowTheNoZone = () => 
  {
    setState((prev) => ({ ...prev, isShowingNoZone: !prev.isShowingNoZone}));
  }

  const updateTheSituation = (quizFormData: FieldValues) => {
    if (isLoading) return;
    if (state.isSituationAdded) {
      setState((prev) => ({ ...prev, isSituationAdded: false }));
      return;
    }
    // getting the teams data to render in the situation bar
    const defensiveTeam = quizFormData?.defensiveTeam;
    const offensiveTeam = quizFormData?.offensiveTeam;
    // handling error cases
    if (offensiveTeam.label === "ALL") {
      setError("offensiveTeam", { message: "Can not set ALL" });
      return;
    }
    if (defensiveTeam.label === "ALL") {
      setError("defensiveTeam", { message: "Can not set ALL" });
      return;
    }
    if (defensiveTeam.value === offensiveTeam.value) {
      setError("offensiveTeam", { message: "Please select different teams" });
      return;
    }
    // Extracting teams data to store in the global state
    const defensiveTeamData = teams.filter(
      (val) => parseInt(val.value) === parseInt(defensiveTeam.value)
    );
    const offensiveTeamData = teams.filter(
      (val) => parseInt(val.value) === parseInt(offensiveTeam.value)
    );
    const quizSituation = gettingQuizSituationFromFormData(quizFormData);
    setState((prev) => {
      return {
        ...prev,
        quizSituation: quizSituation,
        isSituationAdded: true,
        Teams: {
          defensiveTeam: {
            id: parseInt(defensiveTeamData[0]?.value),
            url: defensiveTeamData[0]?.image,
            wins: defensiveTeamData[0]?.wins ?? 0,
            losses: defensiveTeamData[0]?.losses ?? 0,
            color: defensiveTeamData[0]?.bgColor + "50" ?? "",
          },
          offensiveTeam: {
            id: parseInt(offensiveTeamData[0]?.value),
            url: offensiveTeamData[0]?.image,
            wins: offensiveTeamData[0]?.wins ?? 0,
            losses: offensiveTeamData[0]?.losses ?? 0,
            color: offensiveTeamData[0]?.bgColor + "50" ?? "",
          },
        },
        QuizPageStatus: "situation added",
      };
    });
    setIsFormSubmitted(true);
  };
  const uploadANewQuiz = async () => {
    if (!state.isSituationAdded)
      return alert("please save the situation details");
    // at this point we have not uploaded the video so, setting video link as empty string
    let videoLink: string = "";

    try {
      setIsLoading(true);
      setIsQuizBeingUploaded(true);
      const formdata = new FormData();
      formdata.append("file", state.VideoFile!);
      const quiz = CreateQuizBodyFromState(state);

      // uploading video to aws
      const headers = {
        "Content-Type": "multipart/form-data",
        Authorization: getToken(),
      };
      const url = `${process.env.REACT_APP_BACKEND_URL}/admin/upload/video`;

      const response = await axios.post(url, formdata, {
        headers,
        onUploadProgress: (e) => {
          setProgress(e.progress as number);
        },
      });
      // get the video link from aws and assign it to videLink
      videoLink = response.data.link;
      // now uploading the quiz
      await UploadQuiz({ ...quiz, videoUrl: response.data.link });
      setIsLoading(false);
      toast.success("Quiz created successfully Redirecting to dashboard", {
        position: "top-right",
      });

      resetQuizFormState();
      // if there are no quizzes previously, the below code will get the saved quizzes list so that we can navigate the user to dashboard page

      if (state.quizzes.length === 0) {
        const quizzes = await GetUserQuizzes();
        setState((prev) => ({
          ...prev,
          isLoggedIn: true,
          quizzes: quizzes,
        }));
      }

      /*
       * This timer is just for us to show an indicator to user before navigating to another page.
       */
      const timer = setTimeout(() => {
        setIsQuizBeingUploaded(false);
        navigate("/dash");
        clearTimeout(timer);
      }, 2000);
    } catch (err: any) {
      console.log(err.response);
      setIsQuizBeingUploaded(false);
      const url = `${process.env.REACT_APP_BACKEND_URL}/admin/deleteVideo`;
      toast.error(err.response.data.message[0].slice(2) ?? "Bad Request", {
        autoClose: 2000,
        position: "top-right",
      });
      setIsLoading(false);
      // deleting uploaded video if error occurred
      await axios.post(url, { videoLink });
    }
  };
  const updateAQuiz = async (id: number) => {
    if (!state.isSituationAdded)
      return alert("please save the situation details");
    try {
      setIsLoading(true);
      const quiz = CreateQuizBodyFromState(state);
      setIsQuizBeingUploaded(true);
      // updating the quiz
      await UpdateQuiz(quiz, id);
      setIsLoading(false);
      toast.success("Quiz updated successfully", {
        position: "top-right",
        toastId: "toStopShowingTwice",
      });
      resetQuizFormState();
      const timer = setTimeout(() => {
        setIsQuizBeingUploaded(false);
        navigate(-1);
        clearTimeout(timer);
      }, 2000);
    } catch (error) {
      console.log({ error });
      setIsLoading(false);
    }
  };
  /**
   * The function `getTeams` is an asynchronous function that retrieves a list of teams from a backend API and sets the retrieved data as options for a select input field in a React component.
   */
  const getTeams = async () => {
    try {
      setIsLoading(true);
      const data = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/team/list`
      );
      const options = data.data.map((val: any) => ({
        value: String(val.id),
        label: `${val.name}-${val.school}`,
        image: val.logoUrl,
        school: val.school,
        wins: val.wins,
        losses: val.losses,
        bgColor: val.bgColor,
      }));

      setTeams(options);

      setIsLoading(false);
    } catch (error: any) {
      setIsLoading(false);

      console.log(error.message);
    }
  };
  useEffect(() => {
    /*
     * This is where we set the teams in quiz Form.
     * If we are editing a quiz, we will set the teams that we get from that quiz
     * otherwise we will just set the default value to the teams input.
     */
    if (teams.length === 0) {
      getTeams();
    } else {
      if (state.currentEditingQuiz !== null) {
        const quizform = state.quizSituation!;
        const situation = quizform?.situation!;
        setDefaultValues((prev) => ({
          ...prev,
          clock: situation?.timeLeft,
          defensiveTeam: teams.filter(
            (i) => parseInt(i.value) === state.quizSituation?.defensiveTeam
          )[0],
          defensiveTeamScore: situation.defensiveTeamScore,
          distance: situation.distance,
          down: situation.down,
          offensiveTeam: teams.filter(
            (i) => parseInt(i.value) === state.quizSituation?.offensiveTeam
          )[0],
          offensiveTeamScore: situation.offensiveTeamScore,
          quarter: situation.quarter,
          subscribedTeam: teams.filter(
            (i) => parseInt(i.value) === state.quizSituation?.subscribedTeam
          )[0],
          yardLine: situation.yardLine,
          quizName: quizform.QuizName,
        }));
        setIsFormSubmitted(true);
        // set the form values here to show the video ribbon in editing mode
        setState((prev) => ({ ...prev, isSituationAdded: true }));
      } else {
        setDefaultValues((prev) => ({
          ...prev,
          defensiveTeam: teams[1],
          offensiveTeam: teams[2],
          subscribedTeam: teams[0],
        }));
      }
    }
  }, [teams]);
  // custom styling for select component in "QuizForm" start
  const CustomOption = ({ innerProps, label, data }: any) => {
    return (
      <div
        {...innerProps}
        style={{
          color: "rgb(0,255,0)",
          backgroundColor: "#151521",
          borderRadius: "0",
          margin: "0",
          cursor: "pointer",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "1rem",
          padding: "0.5rem",
          // width: "80%",
        }}
      >
        <img
          src={data.image}
          alt=""
          style={{ marginRight: "1.5rem", width: "1.5rem", height: "1.5rem" }}
        />
        {label}
      </div>
    );
  };
  const customStyles: StylesConfig = {
    control: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: "#151521",
      color: "green",
      border: "none",
      borderRadius: "0",
      boxShadow: "none",
      marginBottom: "0.8rem",
      borderBottom: "1px solid lightgray",
      "&:hover": {
        borderBottom: "1px solid lightgray",
        cursor: "pointer",
      },
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "white",
    }),
    input: (base: any) => ({ ...base, color: "white" }),
    valueContainer: (base: any) => ({
      ...base,
      padding: "0",
      color: "white",
    }),
    menu: (base: any) => ({ ...base, backgroundColor: "#151521" }),
  };
  // custom styling for select component in "QuizForm" end

  // this operation will be performed only by admin so, it's ok if we navigate to creators-quizzes or we can just go back using "-1" as an argument. I am confused. what to use here?
  const deleteTheQuiz = async () => {
    setIsLoading(true);
    try {
      await DeleteAQuiz(deleteId!);
      setIsAlertOpen(false);
      setIsLoading(false);
      navigate("/creators-quizzes");
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const releaseQuizApiCall = async () => {
    try {
      setIsLoading(true);
      const token = getToken();
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/quizzes/releasequiz`,
        {
          quizIds: [state.currentEditingQuiz?.id],
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      navigate("/creators-quizzes");
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const releaseQuiz = () => {
    toast.promise(releaseQuizApiCall, {
      pending: "Releasing the quiz wait for a while",
      success: "quiz released Successfully",
      error: "unable to release quiz",
    });
  };

  return (
    // OK. I know there are a lot of conditional renderings happening here. But, there are a lot of conditions we have to take into account.
    <>
      {/* Modal which shows quiz uploading progress start */}
      {isQuizBeingUploaded && (
        <div className="overlay">
          <div className={`modal `}>
            {state.currentEditingQuiz !== null ? (
              <p>Updating Quiz</p>
            ) : (
              <p>Creating Quiz & Uploading to Server</p>
            )}

            <p>
              {state.VideoFile !== null ||
              parseInt((progress * 100).toFixed(2)) <= 100
                ? parseInt((progress * 100).toFixed(2)) + "%"
                : "almost done.."}
            </p>
          </div>
        </div>
      )}
      {/* Modal which shows quiz uploading progress end */}
      {/* showing preview start */}
      {isPreviewModeOn && <Preview setIsPreviewModeOn={setIsPreviewModeOn} />}
      {/* showing preview end */}
      {/* showing an alert before deleting or changing the mode of a quiz various operations */}
      <div className={`alert delete ${isAlertOpen && " show"} `}>
        <div className="warn">
          {deleteId ? (
            <>
              <p>Are you sure you want to Delete ? </p>
            </>
          ) : (
            <>
              <p>The quiz is created for {state.mode} mode</p>
              <p>Are you sure you want to Change?</p>
            </>
          )}
        </div>
        <div>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="loading-icon" />
          ) : (
            <>
              <button
                onClick={() => {
                  setIsAlertOpen(false);
                  setDeleteId(null);
                }}
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  if (deleteId) {
                    deleteTheQuiz();
                  } else {
                    setState((prev) => {
                      return {
                        ...prev,
                        mode: prev.mode === "Football" ? "General" : "Football",
                      };
                    });
                    setIsAlertOpen(false);
                  }
                }}
              >
                {deleteId ? "Delete" : "Change"}
              </button>
            </>
          )}
        </div>
      </div>
      {/* Main quiz Form start */}
      <div className={`quizform-container `}>
        <div className="form-header">
          <h4>
            Details <IoInformationCircleOutline />
          </h4>
          <div className="switch-container">
            <span>{state.mode}</span>
            <input
              value={state.mode}
              checked={state.mode === "General"}
              type="checkbox"
              onChange={(e) => {
                if (state.currentEditingQuiz !== null) {
                  setIsAlertOpen(true);
                  return;
                }
                if (e.target.checked) {
                  setState((prev) => ({ ...prev, mode: "General" }));
                  return;
                }
                setState((prev) => ({ ...prev, mode: "Football" }));
                return;
              }}
              name="mode"
              id="mode"
            />
            <label htmlFor="mode"></label>
          </div>
        </div>
        <form onSubmit={handleSubmit(updateTheSituation)}>
          <label>
            SUBSCRIBED TEAM
            <br />
            (Team Admin Autoselect Subscribe team)
          </label>
          <Controller
            name="subscribedTeam"
            control={control}
            rules={{
              required: true,
              value: defaultValues.subscribedTeam,
              onChange: OnChange,
            }}
            render={({ field }) => (
              <Select
                {...field}
                options={[...teams]}
                components={{
                  Option: CustomOption,
                }}
                styles={customStyles}
                isClearable
                isSearchable
                // filterOption={customFilterOption}
                isDisabled={state.isSituationAdded}
              />
            )}
          />
          {errors.subscribedTeam && (
            <span className="error">Subscribed Team is required.</span>
          )}
          {state.mode === "Football" && (
            <>
              <label>OFFENSE TEAM</label>
              <Controller
                name="offensiveTeam"
                control={control}
                rules={{
                  required: state.mode === "Football",
                  value: defaultValues.offensiveTeam,
                  onChange: OnChange,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[...teams.filter((t) => t.label !== "ALL-ALL")]}
                    components={{ Option: CustomOption }}
                    styles={customStyles}
                    isSearchable
                    isClearable
                    isDisabled={state.isSituationAdded}
                  />
                )}
              />
              {errors.offensiveTeam && (
                <span className="error">
                  {errors.offensiveTeam.message ||
                    "Offensive Team Name is required."}
                </span>
              )}
              <label>DEFENSE TEAM</label>
              <Controller
                name="defensiveTeam"
                control={control}
                rules={{
                  required: state.mode === "Football",
                  value: defaultValues.defensiveTeam,
                  onChange: OnChange,
                }}
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[...teams.filter((t) => t.label !== "ALL-ALL")]}
                    components={{ Option: CustomOption }}
                    styles={customStyles}
                    isSearchable
                    isClearable
                    isDisabled={state.isSituationAdded}
                  />
                )}
              />
              {errors.defensiveTeam && (
                <span className="error">
                  {errors.defensiveTeam.message ||
                    "defensive Team Name is required."}
                  .
                </span>
              )}

              <div className="multipleInputs">
                <div className="field ">
                  <label htmlFor="score">SCORE</label>
                  <div className="score">
                    <input
                      className="offense"
                      readOnly={state.isSituationAdded}
                      {...register("offensiveTeamScore", {
                        required: {
                          value: state.mode === "Football",
                          message: "Offensive Required",
                        },
                        onChange: OnChange,
                        maxLength: {
                          value: 2,
                          message: "Too Many Characters",
                        },
                      })}
                      type="text"
                      placeholder="O"
                      autoComplete="off"
                    />
                    <span>-</span>
                    <input
                      className="defense"
                      type="text"
                      autoComplete="off"
                      placeholder="D"
                      readOnly={state.isSituationAdded}
                      {...register("defensiveTeamScore", {
                        required: {
                          value: state.mode === "Football",
                          message: "Defensive Required",
                        },
                        onChange: OnChange,
                        maxLength: {
                          value: 2,
                          message: "Too Many Characters",
                        },
                      })}
                    />
                  </div>
                </div>
                <div className="field quarter">
                  <label htmlFor="quarter">QUARTER</label>

                  <fieldset disabled={state.isSituationAdded}>
                    <select
                      id="quarter"
                      {...register("quarter", {
                        required: state.mode === "Football",
                        onChange: OnChange,
                      })}
                    >
                      {[1, 2, 3, 4].map((val: number) => (
                        <option key={val} value={val}>
                          {val}
                        </option>
                      ))}
                    </select>
                  </fieldset>
                </div>
                <div className="field clock">
                  <label htmlFor="clock">CLOCK TIME</label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Enter Time"
                    readOnly={state.isSituationAdded}
                    {...register("clock", {
                      required: state.mode === "Football",
                      pattern: /^(0[0-9]|1[0-4]|[1-9]|14):([0-5][0-9])$/,
                      onChange: OnChange,
                    })}
                  />
                </div>
              </div>

              {(errors.defensiveTeamScore && (
                <span className="error">
                  {errors.defensiveTeamScore.message}
                </span>
              )) ||
                (errors.offensiveTeamScore && (
                  <span className="error">
                    {errors.offensiveTeamScore.message}
                  </span>
                )) ||
                (errors.quarter && (
                  <span className="error">Quarter is required</span>
                )) ||
                (errors.clock && (
                  <span className="error">
                    Clock is required and should be MM:SS format
                  </span>
                ))}

              <div className="multiple">
                <div className="field">
                  <label htmlFor="down">DOWN</label>
                  <input
                    type="text"
                    autoComplete="off"
                    readOnly={state.isSituationAdded}
                    {...register("down", {
                      required: state.mode === "Football",
                      onChange: OnChange,
                    })}
                    placeholder="Down"
                  />
                </div>
                <div className="field">
                  <label htmlFor="distance">DISTANCE</label>
                  <input
                    type="text"
                    autoComplete="off"
                    readOnly={state.isSituationAdded}
                    {...register("distance", {
                      required: state.mode === "Football",
                      onChange: OnChange,
                    })}
                    id=""
                    placeholder="Distance"
                  />
                </div>
                <div className="field">
                  <label htmlFor="yardline">YARDLINE</label>
                  <input
                    type="text"
                    autoComplete="off"
                    readOnly={state.isSituationAdded}
                    {...register("yardLine", {
                      required: state.mode === "Football",
                      onChange: OnChange,
                    })}
                    id=""
                    placeholder="Yardline"
                  />
                </div>
              </div>
              <p className="errors">
                {errors.down && (
                  <span className="error">Down is required.</span>
                )}
                {errors.distance && (
                  <span className="error">Distance is required.</span>
                )}
                {errors.yardLine && (
                  <span className="error">Yard line is required.</span>
                )}
              </p>
            </>
          )}
          {!isLoading && (
            <button disabled={isLoading}>
              {state.isSituationAdded
                ? "EDIT THE SITUATION"
                : "UPDATE SITUATION RIBBON"}
            </button> 
          )}
          {!isLoading && (
            <button 
              disabled={isLoading}
              type="button"
              onClick={toggleShowTheNoZone}
            >
              {state.isShowingNoZone
                ? "HIDE QUESTION PLACEHOLDER"
                : "SHOW QUESTION PLACEHOLDER"}
            </button>
          )}
        </form>
        {/* Below are just buttons with different onClick callbacks and different labels for different conditions */}
        {state.QuizQuestions.length > 0 && (
          <>
            <ButtonWithLoadingState
              isLoading={isLoading}
              label={
                state.currentEditingQuiz !== null
                  ? "UPDATE QUIZ"
                  : state.QuizQuestions.length > 0
                  ? "UPLOAD THE QUIZ"
                  : ""
              }
              attr={{
                onClick: () => {
                  if (!state.isSituationAdded)
                    return alert("please save the situation details");
                  if (state.currentEditingQuiz !== null) {
                    return updateAQuiz(state.currentEditingQuiz.id);
                  }
                  return uploadANewQuiz();
                },
              }}
            />
            <div className="button-container">
              <ButtonWithLoadingState
                isLoading={isLoading}
                label="Preview"
                attr={{
                  onClick: () => setIsPreviewModeOn(true),
                }}
              />
              {state.user?.role === "super_admin" && (
                <>
                  {!state.currentEditingQuiz?.isReleased &&
                    state.currentEditingQuiz !== null && (
                      <ButtonWithLoadingState
                        attr={{ onClick: releaseQuiz }}
                        isLoading={isLoading}
                        label="Release"
                      />
                    )}
                  {state.currentEditingQuiz && (
                    <ButtonWithLoadingState
                      isLoading={isLoading}
                      label="Delete"
                      attr={{
                        onClick: () => {
                          setIsAlertOpen(true);
                          setDeleteId(state.currentEditingQuiz?.id!);
                        },
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </>
        )}
      </div>
      {/* Main quiz Form end */}
    </>
  );
};

export default QuizForm;
