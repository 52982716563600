import React, { useRef, useState } from "react";
import { QuestionType, QuizQuestion } from "../../lib/types";
const MAX_LENGTH_QUESTION = 60;
const MAX_LENGTH_ANSWER = 24;
const useQuestionFields = () => {
  const questionRef = useRef<HTMLInputElement | null>(null);
  const answerRef = useRef<HTMLInputElement | null>(null);
  const optionARef = useRef<HTMLInputElement | null>(null);
  const optionBRef = useRef<HTMLInputElement | null>(null);
  const optionCRef = useRef<HTMLInputElement | null>(null);
  const [fields, setFields] = useState<QuizQuestion>({
    question: {
      value: "",
      error: "",
      ref: questionRef,
    },
    answer: {
      value: "",
      error: "",
      ref: answerRef,
    },
    optionA: {
      value: "",
      error: "",
      ref: optionARef,
    },
    optionB: {
      value: "",
      error: "",
      ref: optionBRef,
    },
    optionC: {
      value: "",
      error: "",
      ref: optionCRef,
    },
  });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target!;
    if (
      fields[name as keyof typeof fields].ref.current!.value.length >
      (name === "question" ? MAX_LENGTH_QUESTION : MAX_LENGTH_ANSWER)
    ) {
      setFields((prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name as keyof typeof prev],
            value: value,
            error: `${
              name === "question" ? "Question" : "Answer"
            } should be less than ${
              name === "question" ? MAX_LENGTH_QUESTION + 1 : MAX_LENGTH_ANSWER + 1
            } characters`,
          },
        };
      });
    } else {
      setFields((prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name as keyof typeof prev],
            value: value,
            error: ``,
          },
        };
      });
    }
  };
  const reset = () => {
    setFields({
      question: {
        value: "",
        error: "",

        ref: questionRef,
      },
      answer: {
        value: "",
        error: "",

        ref: answerRef,
      },
      optionA: {
        value: "",
        error: "",

        ref: optionARef,
      },
      optionB: {
        value: "",
        error: "",

        ref: optionBRef,
      },
      optionC: {
        value: "",
        error: "",

        ref: optionCRef,
      },
    });
  };
  const FillExistingQuestion = (q: QuestionType) => {
    setFields({
      question: {
        value: q.question,
        error: "",

        ref: questionRef,
      },
      answer: {
        value: q.answer,
        error: "",

        ref: answerRef,
      },
      optionA: {
        value: q.options[0],
        error: "",

        ref: optionARef,
      },
      optionB: {
        value: q.options[1],
        error: "",

        ref: optionBRef,
      },
      optionC: {
        value: q.options[2],
        error: "",

        ref: optionCRef,
      },
    });
  };
  return {
    questionRef,
    answerRef,
    optionARef,
    optionBRef,
    optionCRef,
    fields,
    setFields,
    handleChange,
    FillExistingQuestion,
    reset,
  };
};

export default useQuestionFields;
