import axios from "axios";
import React, { useState } from "react";

import { FieldValues, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const SendOTPForm = ({
  setFormStages,
  setEmail,
}: {
  setFormStages: React.Dispatch<
    React.SetStateAction<{
      sendotp: boolean;
      setNewPassword: boolean;
    }>
  >;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const sendOtp = async (data: FieldValues) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/forgot-password`,
        {
          email: data.email,
        }
      );
      if (response.data.status === 400) {
        toast.warning(response.data.message);
        setIsLoading(false);
      } else {
        toast.success("OTP is send to registered email address");
        setFormStages({ sendotp: false, setNewPassword: true });
        setEmail(data.email);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <ToastContainer />
      <form className="form" onSubmit={handleSubmit((data) => sendOtp(data))}>
        <p className="login-title">FORGOT PASSWORD</p>
        <label htmlFor="email">EMAIL ADDRESS *</label>
        <input
          type="text"
          placeholder="Enter Email address"
          {...register("email", { required: true })}
        />
        {errors.email && <span>Email is required.</span>}
        <button>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="loading-icon" />
          ) : (
            "SEND OTP"
          )}
        </button>
        <p className="signin">
          Already have an account? <Link to="/">Sign in</Link>
        </p>
      </form>
    </>
  );
};

export default SendOTPForm;
