import React, { useState, useRef, useEffect, useContext } from "react";
import ReactPlayer from "react-player";
import { formatTime } from "../../../utils/format";
import { MdUpload } from "react-icons/md";
import "./player.scss";
import { TbPlayerPlay, TbPlayerPauseFilled } from "react-icons/tb";
import TimeLine from "./TimeLine";
import { OnProgressProps } from "react-player/base";
import { Context } from "../../../utils/Context";
import addNumberSuffix from "../../../utils/AddNumberSuffix";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
const Player = ({
  isformSubmitted,
  setIsVideoLoaded,
  isVideoBuffering,
  setIsVideBuffering,
}: {
  isformSubmitted: boolean;
  setIsVideoLoaded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsVideBuffering: React.Dispatch<React.SetStateAction<boolean>>;
  isVideoBuffering: boolean;
}) => {
  const [videoState, setVideoState] = useState({
    playing: false,
    muted: true,
    volume: 0.5,
    played: 0,
    seeking: false,
    Buffer: false,
    playbackRate: 1,
  });
  const { playing, muted, played, playbackRate, seeking } = videoState;
  const [videoUrl, setVideoUrl] = useState("");
  const { state, setState } = useContext(Context)!;
  const situation = state.quizSituation?.situation || null;
  const [videoDuration, setVideoDuration] = useState(0);
  const videoPlayerRef = useRef<ReactPlayer>(null);
  const currentTime = videoPlayerRef.current
    ? videoPlayerRef.current.getCurrentTime()
    : "00:00";
  const duration = videoPlayerRef.current
    ? videoPlayerRef.current.getDuration()
    : "00:00";

  const formatCurrentTime = formatTime(currentTime as number);
  const formatDuration = formatTime(duration as number);

  /**
   * The function `LoadTheVideoFile` checks the properties of a selected video file and performs various validations before setting the video URL and updating the state.
  
   */
  function LoadTheVideoFile(files: FileList) {
    if (files![0]) {
      const video = document.createElement("video");
      video.src = URL.createObjectURL(files![0]);
      video.addEventListener("loadedmetadata", function () {
        console.log({ d: video.duration });
        if (
          Math.round(video.duration) > 300 ||
          Math.round(video.duration) < 15
        ) {
          toast.warning(
            "Video duration can't be greater than 5 minutes or less than 15 seconds"
          );
        } else if (Math.round(files![0].size / 1024 / 1024) >= 200) {
          toast.warning("File Size should be less than 200MB");
        } else if (files![0].type !== "video/mp4") {
          toast.warning("Accepted Files are mp4");
        } else {
          setVideoUrl(URL.createObjectURL(files![0]));
          console.log(files[0]);
          setState((prev) => ({
            ...prev,
            VideoFile: files![0],
            videoUrl: URL.createObjectURL(files![0]),
          }));
          setIsVideoLoaded(true);
        }
      });
    }
  }

  const playPauseHandler = () => {
    if (videoUrl) {
      setVideoState((prev) => ({ ...prev, playing: !prev.playing }));
    }
  };

  /**
   * The progressHandler function updates the video state if the video is playing and not seeking.
   * @param {OnProgressProps} state - The `state` parameter is of type `OnProgressProps`.
   *It  contains information about the progress of the video being played, such as the current time, duration, and percentage played.
   */
  const progressHandler = (state: OnProgressProps) => {
    if (playing && !seeking) {
      setVideoState({
        ...videoState,
        played: state.played,
      });
    }
  };

  const seekHandler = (value: any) => {
    setVideoState({ ...videoState, played: parseFloat(value) / 100 });
  };
  /**
   * The seekMouseUpHandler function updates the video state, calculates the click position on the progress bar, calculates the click percentage, calculates the seek time, and seeks to the calculated time on the video player.
   * @param {any} e - The parameter "e" is an event object that represents the mouseup event. It
   * contains information about the event, such as the target element and the client coordinates of the mouse pointer.
   */
  const seekMouseUpHandler = (e: any) => {
    setVideoState({ ...videoState, seeking: false });
    const progressBar = e.target;
    const clickPosition = e.clientX - progressBar.getBoundingClientRect().left;
    const progressBarWidth = progressBar.offsetWidth;
    const clickPercentage = clickPosition / progressBarWidth;
    const seekTime = clickPercentage * videoPlayerRef.current!.getDuration();
    videoPlayerRef.current!.seekTo(seekTime);
  };

  const getBackgroundSize = () => {
    return {
      backgroundSize: `${played * 100}% 100%`,
    };
  };
  /* `useEffect` hook is used to update the video URL and set the video as loaded when the
`state.videoUrl` changes. */
  useEffect(() => {
    if (state.currentEditingQuiz !== null) {
      if (state.videoUrl !== null) {
        setVideoUrl(state.videoUrl);
        setIsVideoLoaded(true);
      }
    }
  }, [state.videoUrl]);
  return (
    <>
      <ToastContainer autoClose={2000} />
      {!videoUrl ? (
        <div
          className="uploader"
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(e) => {
            e.preventDefault();
            LoadTheVideoFile(e.dataTransfer.files);
          }}
        >
          <MdUpload color="white" className="upload" />

          <span>Drag and Drop Video here</span>
          <span>Your videos will be private until publish them.</span>
          <label htmlFor="file-upload" className="file-upload-label">
            SELECT FILE
          </label>
          <input
            type="file"
            id="file-upload"
            accept="video/mp4"
            onChange={(e) => LoadTheVideoFile(e.target.files!)}
          />
        </div>
      ) : (
        <div className={`player`} onClick={playPauseHandler}>
          {isVideoBuffering && (
            <div className="loading-overlay">
              <AiOutlineLoading3Quarters className="loading-icon buffer-loading" />
            </div>
          )}
          <ReactPlayer
            height={"100%"}
            ref={videoPlayerRef}
            id="video-player"
            style={{
              padding: "0",
              margin: "0",
              width: "100%",
              height: "100%",
            }}
            onBuffer={() => {
              setIsVideBuffering(true);
            }}
            onBufferEnd={() => {
              setIsVideBuffering(false);
            }}
            url={videoUrl}
            width={"100%"}
            playing={playing}
            onProgress={progressHandler}
            playbackRate={playbackRate}
            progressInterval={300}
            onReady={(e) => {
              setVideoDuration(e.getDuration());
              setState((prev) => ({ ...prev, videoDuration: e.getDuration() }));
            }}
          />
          {/* No-Zone Display starts */}
          {
            state.isShowingNoZone && 
            (
              <div className="no-zone">
                <div className="no-zone-question">
                  Question Placeholder
                </div>
                <div className="no-zone-grid">
                  <div className="no-zone-answer">
                    Answer A
                  </div>
                  <div className="no-zone-answer">
                    Answer B
                  </div>
                  <div className="no-zone-answer">
                    Answer C
                  </div>
                  <div className="no-zone-answer">
                    Answer D
                  </div>
                </div>
              </div>
            )
          }
          {/* No-Zone Display ends */}
          {/* Situation Bar starts */}
          <div className="situation-bar">
            {state.isSituationAdded && state.mode === "Football" && (
              <div className="video-bar">
                <div className="child1">On the Hash</div>
                <div
                  className="child2"
                  style={{
                    backgroundColor: state.Teams?.offensiveTeam.color!,
                  }}
                >
                  <span>
                    <img
                      className="logo"
                      src={state.Teams?.offensiveTeam.url}
                      alt=""
                    />
                  </span>
                  <span>{`${state.Teams?.offensiveTeam.wins}-${state.Teams?.offensiveTeam.losses}`}</span>
                  <span className="score">{situation?.offensiveTeamScore}</span>
                </div>
                <div
                  className="child3"
                  style={{
                    backgroundColor: state.Teams?.defensiveTeam.color!,
                  }}
                >
                  <span>
                    <img
                      className="logo"
                      src={state.Teams?.defensiveTeam.url}
                      alt=""
                    />
                  </span>
                  <span>{`${state.Teams?.defensiveTeam.wins}-${state.Teams?.defensiveTeam.losses}`}</span>
                  <span className="score">{situation?.defensiveTeamScore}</span>
                </div>
                <div className="child4">
                  <span>{`${addNumberSuffix(situation?.quarter!)}   `}</span>
                  <span>{situation?.timeLeft}</span>
                </div>
                <div className="child5">
                  <span>{addNumberSuffix(situation?.down!)} &</span>
                  <span>{situation?.distance}</span>
                </div>
              </div>
            )}
          </div>
          {/* Situation Bar ends */}
        </div>
      )}

      {videoUrl && (
        <div className="timecontrols">
          <div className="slider-container">
            <input
              type="range"
              min={0}
              max={100}
              value={played * 100}
              className="slider"
              onClick={seekMouseUpHandler}
              onChange={(e) => {
                seekHandler(e.target.value);
              }}
              id="myRange"
              style={getBackgroundSize()}
            />
          </div>
          <div className="timers">
            <span>{formatCurrentTime}</span>
            <span>{formatDuration}</span>
          </div>
        </div>
      )}
      {/* play and pause icon and control start */}
      {videoUrl && (
        <div className="controls">
          {playing && videoState.played < 1 ? (
            <TbPlayerPauseFilled color="white" onClick={playPauseHandler} />
          ) : (
            <TbPlayerPlay color="white" onClick={playPauseHandler} />
          )}
        </div>
      )}
      {/* play and pause icon and control end */}

      {/* once the form is submitted we will show the timeline below player and situation bar in the player */}
      {isformSubmitted && (
        <TimeLine
          src={videoUrl}
          videoPlayerRef={videoPlayerRef}
          played={played}
          playing={playing}
          videoDuration={videoDuration}
        />
      )}
    </>
  );
};

export default Player;
