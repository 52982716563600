export const situation = [
  "How much time is left in the quarter?",

  "What down is it?",

  "How may yards for a first down?",

  "What yard line is the ball on?",

  "What is the score?",
];
export const preSnap = [
  "What is the offensive personnel?",

  "How many safeties are there?",

  ,
  "What front is the defensive line in?",

  ,
  "What coverage is the defense running?",

  ,
  "Was there a player in motion?",

  ,
];
export const postSnap = [
  "What is the play type?",

  "What is the offensive blocking scheme?",

  "How many yards were gained on the play?",

  "Which position made the tackle on the previous play?",

  "Which player made a key offensive contribution on the play?",
];

export const personnel = [
  10,

  11,

  12,

  21,

  22,

  23,
];
export const positions = ["QB", "RB", "OL", "WR", "TE", "FB", "DL", "LB", "DB"];

export const quarters = ["1st", "2nd", "3rd", "4th"];
