import axios from "axios";
import React, { useEffect, useState, useContext } from "react";
import { AiFillEyeInvisible, AiOutlineLoading3Quarters } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { FieldValues, useForm } from "react-hook-form";
import { ImCross } from "react-icons/im";
import "./addcreators.scss";

import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import getToken from "../../../utils/token";
import { Context } from "../../../utils/Context";

const AddContentCreator = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { state } = useContext(Context)!;
  const email = searchParams.get("email");
  const firstName = searchParams.get("firstName");
  const lastName = searchParams.get("lastName");
  const social = searchParams.get("socials")?.slice(1, -1);
  const addUserApi = async (data: FieldValues) => {
    setIsLoading(true);
    try {
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: getToken(),
        },
      };
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin/adduser`,
        { ...data, role: "content_creator" },
        axiosConfig
      );
      toast.success("user added successfully");
      reset();
      navigate("/creators");
      setIsLoading(false);
    } catch (error: any) {
      toast.error(error.response.data.error || "Unable to process request");
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (state.isLoggedIn === false) {
      navigate("/");
    }
    setValue("email", email || "");
    setValue("firstName", firstName || "");
    setValue("lastName", lastName || "");
    setValue("social", social || "");
  }, []);

  return (
    <div className="add-user-container">
      <ToastContainer />
      <div className="add-new-user">
        <div className="header-cross">
          <h3>ADD NEW USER</h3>
          <ImCross className="close" onClick={() => navigate("/creators")} />
        </div>

        <form
          className="add-user-form"
          onSubmit={handleSubmit((data) => addUserApi(data))}
        >
          <label htmlFor="email">EMAIL ADDRESS*</label>
          <input
            type="email"
            id="email"
            {...register("email", { required: true })}
          />
          {errors.email && <span className="error">Email is required.</span>}
          <label htmlFor="firstName">FIRST NAME*</label>
          <input
            type="text"
            id="firstName"
            {...register("firstName", { required: true })}
          />
          {errors.firstName && (
            <span className="error">First Name is required.</span>
          )}

          <label htmlFor="lastName">LAST NAME*</label>
          <input
            type="text"
            id="lastName"
            {...register("lastName", { required: true })}
          />
          {errors.lastName && (
            <span className="error">Last Name is required.</span>
          )}

          {social && (
            <>
              <label htmlFor="Social">SOCIAL LINK'S*</label>
              <ul className="social-links">
                {social !== null
                  ? social
                      .trim()
                      .split(",")
                      .map((val: string) => (
                        <li>
                          <a target="_blank" rel="noreferrer" href={val}>
                            {val}
                          </a>
                        </li>
                      ))
                  : null}
              </ul>
            </>
          )}
          {/* <input
            type="text"
            id="social"
            {...register("social", { required: true })}
          />
          {errors.social && (
            <span className="error">social link is required.</span>
          )} */}

          <div>
            <label htmlFor="password">PASSWORD*</label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              {...register("password", { required: true })}
            />

            <span
              onClick={() => {
                setShowPassword((prev) => !prev);
              }}
            >
              {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
            </span>

            {errors.password && (
              <span className="error">Password is required.</span>
            )}
          </div>

          <button>
            {isLoading ? (
              <AiOutlineLoading3Quarters className="loading-icon" />
            ) : (
              "ADD"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddContentCreator;
