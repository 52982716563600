import "./Register.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { SendNewAccountRequest } from "../../utils/api/auth";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { CgAddR } from "react-icons/cg";
import { MdDelete } from "react-icons/md";

const Register = () => {
  const {
    register,
    handleSubmit,
    reset,
    getValues,

    watch,
    setValue,
    formState: { errors },
  } = useForm();
  const socialInput = watch("social", "");
  const [isLoading, setIsLoading] = useState(false);
  const [socialUrls, setSocialUrls] = useState<string[]>([]);
  const [error, setError] = useState("");
  const Signupuser = async (data: FieldValues) => {
    if (socialUrls.length < 1)
      return setError("please add at least one social media link");
    const urlString = socialUrls.join(",");
    const body = { ...data, social: urlString };
    setIsLoading(true);
    try {
      await SendNewAccountRequest(body);
      toast.success("Request sent");
      setIsLoading(false);
      reset();
      setSocialUrls([]);
      setError("");
    } catch (error: any) {
      toast.error(error.response.data.error);
      setIsLoading(false);

      console.log(error);
    }
  };
  const addUrlToList = () => {
    const v = getValues("social");
    if (v.trim().length > 3) {
      setSocialUrls((prev) => [...prev, v.trim()]);
      setError("");
      setValue("social", "");
    }
  };
  const deleteFromList = (currentIndex: number) => {
    setSocialUrls((prev) =>
      prev.filter((value, index) => currentIndex !== index)
    );
  };

  return (
    <div className="main-register">
      <ToastContainer />
      <div className="register">
        <div className="onthehash">
          <span>ONTHE</span>
          <br />
          <span>HASH</span>
        </div>

        <form
          className="form"
          onSubmit={handleSubmit((data) => Signupuser(data))}
        >
          <p className="login-title">BECOME A CREATOR</p>
          <label htmlFor="email">EMAIL ADDRESS *</label>
          <input
            type="text"
            placeholder="Enter Email address"
            {...register("email", { required: true })}
          />
          {errors.email && <span>Email is required.</span>}
          <label htmlFor="firstName">FIRST NAME *</label>
          <input
            type="text"
            placeholder="Enter First Name"
            {...register("firstName", { required: true })}
          />
          {errors.firstName && (
            <span className="error">First Name is required.</span>
          )}
          <label htmlFor="lastName">LAST NAME *</label>
          <input
            type="text"
            placeholder="Enter Last Name"
            {...register("lastName", { required: true })}
          />
          {errors.lastName && (
            <span className="error">Last Name is required.</span>
          )}
          <label htmlFor="message">MESSAGE *</label>
          <textarea
            id="message"
            placeholder="Enter Your Message"
            {...register("message", { required: true })}
          ></textarea>
          {errors.message && <span>Message is required.</span>}
          {socialUrls.map((url, index) => (
            <li key={index}>
              {url}

              <MdDelete onClick={() => deleteFromList(index)} />
            </li>
          ))}
          <div>
            <label htmlFor="urls">SOCIAL'S *</label>
            <input
              id="urls"
              placeholder="Enter Your Url's here"
              {...register("social", {})}
            />
            {socialUrls.length < 3 && socialInput.length > 3 && (
              <CgAddR className="add-icon" onClick={addUrlToList} />
            )}
          </div>
          {error && <span>Atleast one social url is required.</span>}
          <button>
            {isLoading ? (
              <AiOutlineLoading3Quarters className="loading-icon" />
            ) : (
              "REGISTER"
            )}
          </button>
          <p className="signup">
            Already have an account? <Link to="/">Sign in</Link>
          </p>
        </form>
      </div>
      <div className="image-container">
        <img src="/signin.png" alt="" />
      </div>
    </div>
  );
};

export default Register;
