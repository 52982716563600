import React, { useContext, useEffect, useState } from "react";
import "./timeline.scss";
import EnterQuestion from "./EnterQuestion";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import Tracker from "./Tracker";
import ReactPlayer from "react-player";
import { QuestionType, TrackerPosition } from "../../../lib/types";
import { Context } from "../../../utils/Context";
import "react-toastify/dist/ReactToastify.css";

const TimeLine = ({
  src,
  videoPlayerRef,
  played,
  playing,
  videoDuration,
}: {
  src: any;
  videoPlayerRef: React.RefObject<ReactPlayer>;
  played: number;
  playing: boolean;
  videoDuration: number;
}) => {
  const [frames, setFrames] = useState<string[]>([]);
  const [isLevelsContainerVisible, setIsLevelsContainerVisible] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  // these represent each vertical Tracker position in timeline
  const [trackerPositions, setTrackerPositions] = useState<TrackerPosition[]>(
    []
  );
  const [currentQuestionTimeId, setCurrentQuestionTimeId] = useState(0);
  const [questionStatus, setQuestionStatus] = useState<"active" | "none">(
    "none"
  );
  const [questions, setQuestions] = useState<QuestionType[]>([]);
  const { state, setState } = useContext(Context)!;

  const generateVideoThumbnails = (src: any) => {
    setIsLoading(true);
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const video = document.createElement("video");

      video.autoplay = true;
      video.muted = true;
      video.src = src;
      video.crossOrigin = "anonymous";
      const frames: string[] = [];

      video.onloadeddata = async () => {
        let ctx = canvas.getContext("2d");

        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        video.pause();
        const increment = Math.floor(Math.floor(video.duration) / 6);
        for (let i = 0; i < Math.round(video.duration); i = i + increment) {
          video.currentTime = i;
          ctx?.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
          const dataUrl = canvas.toDataURL("image/jpeg", 0.3);
          const blob = await (await fetch(dataUrl)).blob();
          const blobUrl = URL.createObjectURL(blob);
          frames.push(blobUrl);
        }
        setFrames(frames);
        resolve(frames);
        setIsLoading(false);
      };
    });
  };

  /**
   * The function "MarkItForAddingNewQuestion" updates various states and adds a new question with a
   * specific time to the tracker positions.
   */
  const MarkItForAddingNewQuestion = () => {
    const currentTime = parseFloat(
      videoPlayerRef.current!.getCurrentTime().toFixed(3)
    );
    // once user Click on "+" icon we will show levels
    setIsLevelsContainerVisible(true);
    setQuestionStatus("active");

    setTrackerPositions((prev) => {
      const qTimes: number[] = [];
      questions.forEach((item) => {
        qTimes.push(item.time);
      });
      // marking the previous tracker as inactive
      // ignore the poor naming
      const d = prev.filter((item) => qTimes.indexOf(item.time) >= 0);
      const t = d.map((prev) => ({ ...prev, active: false }));
      // making the current tracker as active
      return [...t, { active: true, progress: played, time: currentTime }];
    });
    setCurrentQuestionTimeId(currentTime);
  };

  // making the existing marker active
  const MakeThisTrackerActive = (played: number, time: number) => {
    setTrackerPositions((prev) => {
      return prev.map((item, i) => {
        if (item.progress === played) {
          return { ...item, active: true };
        } else return { ...item, active: false };
      });
    });

    setCurrentQuestionTimeId((prev) => {
      if (state.currentEditingQuiz !== null) return time;
      return trackerPositions.filter((i) => i.progress === played)[0].time;
    });
    setIsLevelsContainerVisible(true);
  };
  useEffect(() => {
    if (src) {
      generateVideoThumbnails(src);
    }
  }, [src]);

  useEffect(() => {
    setState((prev) => {
      return { ...prev, QuizQuestions: questions };
    });
  }, [questions]);
  useEffect(() => {
    /* This code block is checking if the `currentEditingQuiz` property in the `state` object is not
   null. If it is not null, it means that there is a quiz being edited. */
    if (state.currentEditingQuiz !== null) {
      const videoDuration = videoPlayerRef.current!.getDuration();
      const filteredQuiz = state.currentEditingQuiz;
      const trackerValues: {
        time: number;
        progress: number;
        active: boolean;
      }[] = [];
      const q: QuestionType[] = [];

      filteredQuiz.questions.forEach((i) => {
        q.push({
          answer: i.answer,
          level: i.level,
          options: i.options,
          question: i.question,

          time: i.time,
          timingId: i.timingId,
          id: i.id,
        });
        //
        /*Below code is  checking if there is already a tracker with the same time value as the current question being processed.
         * If there is, it means that a tracker for that time value has already been  added, so there is no need to add another one. In that case, the code returns and does not add a duplicate tracker. */
        if (trackerValues.filter((q) => q.time === i.time).length > 0) return;
        if (videoDuration > 0) {
          trackerValues.push({
            active: false,
            progress: i.time / videoDuration,
            time: i.time,
          });
        }
      });

      setTrackerPositions(trackerValues);
      setQuestions(q);
      setQuestionStatus("active");
    }
  }, [videoDuration]);
  return (
    <>
      {src && (
        <div className="wrapper">
          <div className="tracker-wrapper">
            {isLoading ? null : (
              <>
                {/* This tracker is to move along with video */}
                <Tracker
                  played={played}
                  isVideoPlaying={playing}
                  MarkItForAddingNewQuestion={MarkItForAddingNewQuestion}
                  questionStatus="none"
                  isActive={false}
                  AmITrackingVideo={true}
                  time={0}
                />
                {/* These trackers are to represent existing questions */}
                {trackerPositions.map((p, i) => {
                  return (
                    <Tracker
                      key={i}
                      AmITrackingVideo={false}
                      played={p.progress}
                      isVideoPlaying={playing}
                      questionStatus={questionStatus}
                      MakeThisTrackerActive={MakeThisTrackerActive}
                      isActive={p.active}
                      time={p.time}
                      questionNumber={i + 1}
                      numberOfQuestions={questions.reduce((prev, current) => {
                        if (current.time === p.time) return prev + 1;
                        else return prev + 0;
                      }, 0)}
                    />
                  );
                })}
              </>
            )}
          </div>
          <div className="timeline">
            {isLoading ? (
              <p>
                <AiOutlineLoading3Quarters className="loading-icon" />
                <span>Loading images please wait..</span>
              </p>
            ) : (
              <>
                <div className="images">
                  {frames?.map((val, index) => (
                    <div className="frames" id={String(index)} key={val}>
                      <img key={index} src={val} alt={String(index)} />
                    </div>
                  ))}
                </div>
                <div className="times">
                  {[
                    ...Array(
                      Math.round(videoPlayerRef.current!.getDuration())
                    ).keys(),
                  ].map((val, index) => (
                    <div key={index}>
                      <span>|</span>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      )}
      {/* Based up on Tracker positions i am displaying levels and question blocks below the timeline */}
      {trackerPositions.map((tracker, i) => {
        if (!tracker.active) return null;
        //  when user click on existing tracker, we will set the 'currentQuestionTimeId' to current time.
        //  we are filtering the questions to get the questions for given ime.
        const questionsAtThisTimeStamp = questions.filter(
          (q) => q.time === currentQuestionTimeId
        );
        return (
          <EnterQuestion
            active={isLevelsContainerVisible}
            key={tracker.progress}
            time={currentQuestionTimeId}
            questions={questionsAtThisTimeStamp}
            setQuestions={setQuestions}
          />
        );
      })}
    </>
  );
};

export default TimeLine;
