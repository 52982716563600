import { createContext, useState } from "react";
import { State, ContextType } from "./ContextTypes";
import JwtDecoder from "../JwtDecoder";
import { useLocation } from "react-router-dom";
export const Context = createContext<null | ContextType>(null);

const ContextProvider = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const [initState, setInitState] = useState<State>({
    quizSituation: null,
    isLoggedIn: JwtDecoder(),
    quizzes: [],
    user: null,
    QuizQuestions: [],
    VideoFile: null,
    videoUrl: null,
    isSituationAdded: false,
    Teams: null,
    videoDuration: 0,
    tempFileNameInServer: "",
    mode: "Football",
    currentEditingQuiz: null,
    isSuperAdminInspecting: false,
    scroller: {
      scrollPosition: 0,
      pathName: location.pathname,
    },
    isShowingNoZone: false,
  });

  const value: ContextType = { state: initState, setState: setInitState };
  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default ContextProvider;
