import { QuizRequestBodyType } from "../../lib/types";
import { State } from "../Context/ContextTypes";

export const CreateQuizBodyFromState = (state: State): QuizRequestBodyType => {
  return {
    ...state.quizSituation!,
    videoDuration: state.videoDuration!,
    type: state.mode,
    videoUrl: state.currentEditingQuiz === null ? " " : state.videoUrl!,
    userId: state.user?.id!,
    questions: state.QuizQuestions,
    breakPoints: state.QuizQuestions.map((i) => ({
      timingId: i.timingId,
      time: i.time,
    })),
  };
};
