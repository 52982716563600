import { useContext, useEffect, useState } from "react";
import "./quiz.scss";
import QuizForm from "../../components/authLayout/quiz/QuizForm";
import Player from "../../components/authLayout/quiz/Player";
import { Context } from "../../utils/Context";
import { useLocation, useNavigate } from "react-router-dom";
import useSetQuiz from "../../utils/hooks/useSetQuiz";

const Quiz = () => {
  const navigate = useNavigate();
  const [isformSubmitted, setIsFormSubmitted] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const { state } = useContext(Context)!;
  const { populateQuizPage } = useSetQuiz();
  const [isVideoBuffering, setIsVideoBuffering] = useState(false);
  const location = useLocation();
  /* `useEffect` hook is being used to populate the quiz page and navigate to the home page if the user is not logged in. */
  useEffect(() => {
    if (state.currentEditingQuiz !== null) {
      populateQuizPage(state.currentEditingQuiz);
    }
    if (state.isLoggedIn === false) {
      navigate("/");
    }
  }, []);
  return (
    <div className="quiz-container">
      <div className="quiz">
        <div className="video-container">
          <Player
            setIsVideoLoaded={setIsVideoLoaded}
            isformSubmitted={isformSubmitted}
            isVideoBuffering={isVideoBuffering}
            setIsVideBuffering={setIsVideoBuffering}
          />
        </div>
        {isVideoLoaded && <QuizForm setIsFormSubmitted={setIsFormSubmitted} />}
      </div>
    </div>
  );
};

export default Quiz;
