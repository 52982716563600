/**
 * The `useSetQuiz` function is a custom hook that updates the state of a React context with quiz data.
 * @returns The `useSetQuiz` hook is returning an object with a single property `populateQuizPage`.
 */
import { useContext } from "react";
import { Context } from "../Context";
import { Quiz } from "../Context/ContextTypes";

const useSetQuiz = () => {
  const { state, setState } = useContext(Context)!;

  /**
   * The function `populateQuizPage` takes a `quiz` object as input and updates the state with the relevant properties from the `quiz` object.
   * @param {Quiz} quiz - The `quiz` parameter is an object that contains various properties related to
   * a quiz.
   */

  const populateQuizPage = (quiz: Quiz) => {
    setState((prev) => {
      return {
        ...prev,
        mode:
          quiz.type.length === 0
            ? "Football"
            : quiz.type !== "General" && quiz.type !== "Football"
            ? "Football"
            : (quiz.type as "General" | "Football"),
        videoUrl: quiz.videoUrl,
        isSituationAdded: true,
        quizSituation: {
          ...prev.quizSituation,
          videoUrl: quiz.videoUrl,
          defensiveTeam: quiz.defensiveTeam.id,
          endTime: new Date(quiz.endTime)!,
          subscribedTeam: quiz.subscribedTeam.id,
          startTime: new Date(quiz.startTime),
          offensiveTeam: quiz.offensiveTeam.id,
          playsId: quiz.playsId,
          situation: quiz.situation,

          QuizName: quiz.QuizName ?? "",
        },

        videoDuration: quiz.videoDuration,

        Teams: {
          offensiveTeam: {
            id: quiz.offensiveTeam.id,
            url: quiz.offensiveTeam.logoUrl,
            losses: quiz.offensiveTeam.losses ?? 0,
            wins: quiz.offensiveTeam.wins ?? 0,
            color: quiz.offensiveTeam.bgColor + "50" ?? "",
          },
          defensiveTeam: {
            id: quiz.defensiveTeam.id,
            url: quiz.defensiveTeam.logoUrl,
            losses: quiz.defensiveTeam.losses ?? 0,
            wins: quiz.defensiveTeam.wins ?? 0,
            color: quiz.defensiveTeam.bgColor + "50" ?? "",
          },
        },
      };
    });
  };

  return { populateQuizPage };
};
export default useSetQuiz;
