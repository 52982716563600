import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../utils/Context";
import useReset from "../../utils/hooks/useReset";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import getToken from "../../utils/token";
import { FaSearch } from "react-icons/fa";
import "./referral.scss";

const Referral = () => {
  const { state, setState } = useContext(Context)!;
  const { resetQuizFormState } = useReset();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<any>([]);
  const [searchText, setSearchText] = useState("");

  const navigate = useNavigate();
  async function getReferalData() {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/users/allusers`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      // const sortedbydate = [
      //   ...response.data.sort(
      //     (a: any, b: any) =>
      //       new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      //   ),
      // ];
      const noreferral = response.data.map((val: any, index: number) => {
        if (val?.referral == "") {
          val.referral = "No referral";
        }
        return val;
      });
      setTableData(noreferral);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    resetQuizFormState();
    getReferalData();
    if (state.isLoggedIn === false) {
      navigate("/");
    }
  }, []);

  return (
    <div className="referral-container">
      <div className="referral">
        <div className="referral-content">
          <div className="content">
            <div className="search-container">
              <div>
                <FaSearch className="search-icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="table-container">
              <table className="table">
                {/* this is to give specific column different width */}
                <colgroup>
                  <col style={{ width: "50%" }} />
                  <col style={{ width: "50%" }} />
                </colgroup>
                <thead>
                  <tr>
                    {["Referral", "Downloads"].map((data, i) => (
                      <th key={i}>{data}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="skeleton">
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                    </tr>
                  ) : (
                    tableData
                      .filter((d: any) => {
                        return d?.referral
                          .toLowerCase()
                          .includes(searchText.toLowerCase());
                      })
                      .map((val: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>{val.referral}</td>
                            <td>{val.count}</td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Referral;
