import { useContext, useEffect, useState } from "react";
import {
  AiOutlineLoading3Quarters,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { dashBoardColumns } from "../../lib/StaticData";
import { DashBoardTableColumns, DashBoardTableData } from "../../lib/types";
import { Context } from "../../utils/Context";
import { Quiz } from "../../utils/Context/ContextTypes";
import { DeleteAQuiz, GetUserQuizzes } from "../../utils/api/quiz";
import { parseQuizzesIntoTableData } from "../../utils/helperFunctions/parseQuizzesIntoTableData";
import useReset from "../../utils/hooks/useReset";
import "./dashboard.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const [deleteQuizId, setDeleteQuizId] = useState(0);
  const [tableHeaders, setTableHeaders] =
    useState<DashBoardTableColumns[]>(dashBoardColumns);
  const [tableData, setTableData] = useState<DashBoardTableData[]>([]);
  const { state, setState } = useContext(Context)!;
  const [searchText, setSearchText] = useState("");
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { resetQuizFormState } = useReset();
  /**
   * The function `renderTheSortedData` updates the table data and headers based on the sorted array, column, and order provided.
   * @param {DashBoardTableData[]} sortedArray - An array of objects representing the data to be sorted and rendered in the table.
   * @param {string} column - The "column" parameter is a string that represents the column in the table that the data is being sorted by.
   * @param {"asc" | "dsc" | "none"} order - The `order` parameter is a string that can have one of three values: "asc", "dsc", or "none". It determines the sorting order of the data in the `sortedArray` parameter.
   */
  const renderTheSortedData = (
    sortedArray: DashBoardTableData[],
    column: string,
    order: "asc" | "dsc" | "none"
  ) => {
    setTableData(sortedArray);
    setTableHeaders((prev) => {
      return prev.map((i) => {
        if (i.title === column) {
          return {
            ...i,
            sortingOrder: order === "none" || order === "dsc" ? "asc" : "dsc",
          };
        } else return i;
      });
    });
  };
  /**
   * sorts an array of table data based on a specified column and order.
   * @param {string} [column] - The `column` parameter is a string that represents the column in the table that you want to sort.
   * @param {"asc" | "dsc" | "none"} [order] - The `order` parameter is an optional parameter that
   * specifies the order in which the data should be sorted. It can have three possible values:
   */
  const sortTableData = (column?: string, order?: "asc" | "dsc" | "none") => {
    const data = [...tableData];
    switch (column) {
      case "SUBSCRIBED TEAM":
        if (order === "none" || order === "dsc") {
          data.sort((a, b) => a.subscribedteam.localeCompare(b.subscribedteam));
        } else {
          data.sort((a, b) => b.subscribedteam.localeCompare(a.subscribedteam));
        }
        renderTheSortedData(data, column, order!);
        break;
      case "OFFENSE TEAM":
        if (order === "none" || order === "dsc") {
          data.sort((a, b) => a.offenseteam.localeCompare(b.offenseteam));
        } else {
          data.sort((a, b) => b.offenseteam.localeCompare(a.offenseteam));
        }
        renderTheSortedData(data, column, order!);

        break;
      case "DEFENSE TEAM":
        if (order === "none" || order === "dsc") {
          data.sort((a, b) => a.offenseteam.localeCompare(b.offenseteam));
        } else {
          data.sort((a, b) => b.offenseteam.localeCompare(a.offenseteam));
        }
        renderTheSortedData(data, column, order!);

        break;
      case "ID":
        data.sort((a, b) => {
          const idA = a.id;
          const idB = b.id;

          if (order === "none" || order === "dsc") {
            return idA - idB;
          } else {
            return idB - idA;
          }
        });
        renderTheSortedData(data, column, order!);
        break;
      case "UPLOAD DATE":
        data.sort((a, b) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();

          if (order === "none" || order === "dsc") {
            return dateA - dateB;
          } else {
            return dateB - dateA;
          }
        });
        renderTheSortedData(data, column, order!);
        break;
      default:
        break;
    }
  };

  const deleteTheQuiz = async () => {
    setIsLoading(true);
    try {
      await DeleteAQuiz(deleteQuizId);
      getQuizzes();
      setState((prev) => ({
        ...prev,
        quizIdWhichIsBeingEdited: null,
      }));
      setIsAlertOpen(false);
      setIsLoading(false);
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const r = parseQuizzesIntoTableData(state.quizzes);
    setTableData(r);
  }, [state.quizzes]);
  const getQuizzes = async () => {
    setIsLoading(true);
    const quizzes: Quiz[] = await GetUserQuizzes();
    if (quizzes.length <= 0) {
      navigate("/quiz");
    }
    setState((prev) => ({
      ...prev,
      quizzes: quizzes,
    }));
    setIsLoading(false);
  };
  useEffect(() => {
    if (state.isLoggedIn) {
      resetQuizFormState();
      getQuizzes();
    } else {
      navigate("/");
    }
  }, []);
  return (
    <div className="dashboard-container">
      <div className="dashboard">
        <div className="dashboard-content">
          <div className="content">
            <div className="search-container">
              <div>
                <FaSearch className="search-icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className="table-container">
              <table className="table">
                {/* this is to give specific column different width */}
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "22%" }} />
                  <col style={{ width: "18%" }} />
                  <col style={{ width: "18%" }} />
                  <col style={{ width: "18%" }} />
                  <col style={{ width: "15%" }} />
                </colgroup>
                <thead>
                  <tr>
                    {tableHeaders.map((data, i) => {
                      if (data.sortingEnabled) {
                        return (
                          <th key={data.title}>
                            {data.title}
                            <span
                              onClick={() =>
                                sortTableData(data.title, data.sortingOrder)
                              }
                            >
                              {data.sortingOrder === "asc" ? (
                                <AiOutlineSortAscending className="sorting asc" />
                              ) : (
                                <AiOutlineSortDescending className="sorting dsc" />
                              )}
                            </span>
                          </th>
                        );
                      } else return <th key={data.title}>{data.title}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="skeleton">
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                    </tr>
                  ) : (
                    tableData
                      .filter(
                        (d) =>
                          d.defenseteam.toLowerCase().includes(searchText) ||
                          d.offenseteam.toLowerCase().includes(searchText) ||
                          d.subscribedteam.toLowerCase().includes(searchText) ||
                          String(d.id).toLowerCase().includes(searchText)
                      )
                      .map((val, index) => {
                        return (
                          <tr key={index}>
                            <td>{val.id}</td>
                            <td>{val.subscribedteam}</td>
                            <td>{val.offenseteam}</td>
                            <td>{val.defenseteam}</td>
                            <td>{val.questions}</td>
                            <td>
                              {new Date(val.date).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              })}
                              <FiEdit
                                className="edit"
                                onClick={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    currentEditingQuiz: state.quizzes.filter(
                                      (q) => q.id === val.id
                                    )[0],
                                  }));
                                  navigate("/quiz");
                                }}
                              />
                              <MdDeleteForever
                                className="delete"
                                onClick={() => {
                                  setIsAlertOpen((prev) => !prev);
                                  setDeleteQuizId(val.id);
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className={`alert delete ${isAlertOpen && "show"}`}>
        <p>Are you sure you want to delete?</p>
        <div>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="loading-icon" />
          ) : (
            <>
              <button
                onClick={() => {
                  setIsAlertOpen(false);
                  setDeleteQuizId(0);
                }}
              >
                Cancel
              </button>
              <button onClick={deleteTheQuiz}>Delete</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
