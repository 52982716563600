import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Context } from "../../utils/Context";
import { FaSearch } from "react-icons/fa";
import { FiEdit, FiUserPlus } from "react-icons/fi";
import { creatorColumns } from "../../lib/StaticData";
import { DashBoardTableColumns, creatorTableData } from "../../lib/types";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";
import getToken from "../../utils/token";
import { MdDeleteForever } from "react-icons/md";
import { DeleteAQuiz } from "../../utils/api/quiz";
import "./creatorsQuizzes.scss";
import useReset from "../../utils/hooks/useReset";
import useDebounce from "../../utils/hooks/useDebounce";

const CreatorQuizzes = () => {
  const { state, setState } = useContext(Context)!;
  const [isLoading, setIsLoading] = useState(false);
  const [tableHeaders, setTableHeaders] =
    useState<DashBoardTableColumns[]>(creatorColumns);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [tableData, setTableData] = useState<any>([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [deleteQuizId, setDeleteQuizId] = useState<number | null>(null);
  const [checked, setChecked] = useState<number[]>([]);
  const scrollRef = useRef<any>();
  const [scrollP, setScrollP] = useState(0);
  const { debounceValue } = useDebounce(scrollP, 200);
  const { resetQuizFormState } = useReset();
  async function getData() {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/quizzes/creatorquizzes`,
        {
          headers: {
            Authorization: getToken(),
          },
        }
      );
      const sortedbydate = [
        ...response.data.sort(
          (a: any, b: any) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        ),
      ];
      if (state.scroller.pathName === "/quiz") {
        setTimeout(() => {
          scrollRef.current.scrollTop = state.scroller.scrollPosition;
        }, 10);
      } else {
        setState((p) => ({
          ...p,
          scroller: { ...p.scroller, scrollPosition: 0 },
        }));
      }

      setTableData(sortedbydate);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    resetQuizFormState();
    getData();
    if (state.isLoggedIn === false) {
      navigate("/");
    }
  }, []);
  const deleteTheQuiz = async () => {
    setIsLoading(true);
    try {
      await DeleteAQuiz(deleteQuizId!);
      setTableData(() =>
        tableData.filter((val: any) => val.id !== deleteQuizId)
      );
      toast.success("Quiz Deleted Successfully");
      setState((prev) => ({
        ...prev,
        quizIdWhichIsBeingEdited: null,
      }));
      setIsAlertOpen(false);
      setIsLoading(false);
    } catch (e: any) {
      console.log(e);
      setIsLoading(false);
    }
  };
  const handleCheck = (event: any) => {
    let updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, Number(event.target.value)];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    setChecked(updatedList);
  };
  const releaseQuizApi = async () => {
    try {
      const token = getToken();
      const data = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/quizzes/releasequiz`,
        {
          quizIds: checked,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const filteringdata = tableData.map((val: any) => {
        if (checked.includes(val.id)) {
          val.isReleased = true;
        }
        return val;
      });
      setTableData(filteringdata);
    } catch (error) {
      console.log(error);
    }
  };
  const releaseQuiz = () => {
    toast.promise(releaseQuizApi, {
      pending: "Releasing the quiz wait for a while",
      success: "quiz released Successfully",
      error: "unable to release quiz",
    });
  };
  const handleScroll = () => {
    setScrollP(scrollRef.current.scrollTop);
  };
  useEffect(() => {
    setState({
      ...state,
      scroller: {
        ...state.scroller,
        scrollPosition: debounceValue,
      },
    });
  }, [debounceValue]);
  return (
    <div className="creator-container">
      <ToastContainer />
      <div className="creator">
        <div className="creator-content">
          <div className="content">
            <div className="search-container">
              <div>
                <FaSearch className="search-icon" />
                <input
                  type="search"
                  name=""
                  id=""
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
              {checked.length > 0 && (
                <button className="creator-link" onClick={releaseQuiz}>
                  Release Quiz
                </button>
              )}
            </div>

            <div
              className="table-container"
              ref={scrollRef}
              onScroll={handleScroll}
            >
              <table className="table">
                {/* this is to give specific column different width */}
                <colgroup>
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "20%" }} />
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "20%" }} />
                </colgroup>
                <thead>
                  <tr>
                    {tableHeaders.map((data, i) => {
                      if (data.sortingEnabled) {
                        return (
                          <th key={data.title}>
                            {data.title}
                            <span
                            //   onClick={() =>
                            //     sortTableData(data.title, data.sortingOrder)
                            //   }
                            >
                              {/* {data.sortingOrder === "asc" ? (
                                <AiOutlineSortAscending className="sorting asc" />
                              ) : (
                                <AiOutlineSortDescending className="sorting dsc" />
                              )} */}
                            </span>
                          </th>
                        );
                      } else return <th key={data.title}>{data.title}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {isLoading ? (
                    <tr className="skeleton">
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                      <td className="skeleton-data"></td>
                    </tr>
                  ) : (
                    tableData
                      .filter(
                        (d: any) =>
                          d.user?.firstName
                            .toLowerCase()
                            .includes(searchText.toLowerCase()) ||
                          String(d.id)
                            .toLowerCase()
                            .includes(searchText.toLowerCase())
                      )

                      .map((val: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td>
                              {val.user?.firstName}-{val.id}
                            </td>
                            <td>
                              {val.subscribedTeam.name +
                                "  -  " +
                                val.subscribedTeam.school}
                            </td>
                            <td>
                              {val.questions.length}questions-
                              {Math.floor(val.videoDuration)} secs
                            </td>
                            <td>{val.noOfQuizPlayers || 0}</td>

                            <td>
                              {new Date(val.createdAt).toLocaleDateString(
                                "en-US",
                                {
                                  year: "numeric",
                                  month: "2-digit",
                                  day: "2-digit",
                                }
                              )}
                              <FiEdit
                                className="edit"
                                onClick={() => {
                                  setState((prev) => ({
                                    ...prev,
                                    currentEditingQuiz: tableData.filter(
                                      (q: any) => q.id === val.id
                                    )[0],
                                    isSuperAdminInspecting: true,
                                  }));
                                  navigate("/quiz");
                                }}
                              />
                              <MdDeleteForever
                                className="delete"
                                onClick={() => {
                                  setIsAlertOpen((prev) => !prev);
                                  setDeleteQuizId(val.id);
                                }}
                              />
                              {!val.isReleased && (
                                <input
                                  className="release-check"
                                  type="checkbox"
                                  value={val.id}
                                  onChange={handleCheck}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div className={`alert delete ${isAlertOpen && "show"}`}>
        <p>Are you sure you want to delete?</p>
        <div>
          {isLoading ? (
            <AiOutlineLoading3Quarters className="loading-icon" />
          ) : (
            <>
              {" "}
              <button
                onClick={() => {
                  setIsAlertOpen(false);
                  setDeleteQuizId(0);
                }}
              >
                Cancel
              </button>
              <button onClick={deleteTheQuiz}>Delete</button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreatorQuizzes;
