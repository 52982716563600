import React from "react";

const Alert = ({
  alertOpen,
  setAlertOpen,
  title,
  buttonText,
  apicall,
}: {
  alertOpen: boolean;
  title: string;
  buttonText: string;
  setAlertOpen: React.Dispatch<React.SetStateAction<boolean>>;
  apicall: () => Promise<void>;
}) => {
  return (
    <div className={`alert delete ${alertOpen && "show"}`}>
      <p>{title}</p>
      <div>
        <button
          onClick={() => {
            setAlertOpen(false);
          }}
        >
          Cancel
        </button>
        <button onClick={apicall}>{buttonText}</button>
      </div>
    </div>
  );
};

export default Alert;
