import "./ForgotPassword.scss";

import { useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import SendOTPForm from "../../components/nonAuthLayout/forgotpassword/SendOTPForm";
import VerifyOTPForm from "../../components/nonAuthLayout/forgotpassword/ResetPasswordForm";

const ForgotPassword = () => {
  const [formStages, setFormStages] = useState({
    sendotp: true,
    setNewPassword: false,
  });
  const [email, setEmail] = useState("");

  return (
    <div className="main-register">
      <ToastContainer />
      <div className="forget-password">
        <div className="onthehash">
          <span>ONTHE</span>
          <br />
          <span>HASH</span>
        </div>

        {formStages.sendotp && (
          <SendOTPForm setFormStages={setFormStages} setEmail={setEmail} />
        )}
        {formStages.setNewPassword && (
          <VerifyOTPForm setFormStages={setFormStages} email={email} />
        )}
      </div>
      <div className="image-container">
        <img src="/signin.png" alt="" />
      </div>
    </div>
  );
};

export default ForgotPassword;
